import {Product} from '../products/product';
import * as moment from 'moment';
import {Address} from '../address';
import {Account} from '@vni/common/accounts/account';
import {User} from '../users/user';
import {RMATotals, RMA} from './rma';

const SHIPPING_PROVIDERS = [
  {
    key: 'FEDEX',
    url: 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber={{trackingNumber}}&cntry_code=us',
    regexp: [
      /(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/,
      /\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/,
      /^[0-9]{15}$/]
  },
  {
    key: 'UPS',
    url: 'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums={{trackingNumber}}',
    regexp: [/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/]
  },
  {
    key: 'CANADA_POST',
    url: 'https://www.canadapost-postescanada.ca/track-reperage/en#/search?searchFor={{trackingNumber}}',
    regexp: [
      /^[A-Z]{2}[0-9]{9}[A-Z]{2}$/i
    ]
  },
  {
    key: 'USPS',
    url: 'https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels={{trackingNumber}}%2C',
    regexp: [
      /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/,
      /^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/,
      /^91[0-9]+$/,
      /^[A-Za-z]{2}[0-9]+US$/]
  }
];

export interface Cart {
  items: OrderProduct[];
}

export const ORDER_STATUS = [
  'PAYMENT_FAILED',
  'PENDING_ACH',
  'PENDING_CASH',
  'PENDING_CREDIT',
  'SHIPPED',
  'PAID',
  'AWAITING_SHIPPING',
  'CANCELLED',
  'PENDING',
  'SUBMITTED',
  'FRAUD'];

export const DISCOUNT_CODES = [
  'SUBSCRIPTION_REWARDS_SILVER',
  'SUBSCRIPTION_REWARDS_GOLD',
  'SUBSCRIPTION_REWARDS_PLATINUM',
  'WHOLESALE_DISCOUNT'
];

export const COUPON_CODES = [
  'FIRST20OFF',
  'FIRST15OFF',
  'FIRST15OFF_FIRSTSHIPFREE'
];

export interface Order {
  id: number;
  date: any;
  status: string;
  price: number;
  retailPrice: number;
  wholesalePrice: number;
  taxAmount: number;
  taxRate: number;
  totalPrice: number;
  totalDiscountAmount: number;
  cv: number;
  pv: number;

  products: OrderProduct[];
  shipping: Shipping;
  flatShippingPrice: number;
  flatShippingDiscountAmount: 0;
  flatShippingDiscountCode: string;
  payment: Payment;
  buyerName: string;
  buyingAccountId: number;
  buyingAccount: Account;
  billingAddress: Address;
  shippingAddress: Address;
  phonePrimary: string;
  email: string;
  trackingNumbers: string[];
  trackingNumberUrls: any;
  inventoryItems: any[];
  subscribe: boolean;
  isSubscription: boolean;
  ipAddress: string;

  returnUntil: any;
  canReturn: boolean;

  rmaTotals: RMATotals;
  rmas: RMA[];
  shopifyId: number;
}

export const deserializeOrder = (order: Order): Order => {
  order.date = moment(order.date).toDate();
  order.returnUntil = moment(order.returnUntil).toDate();

  if (order.trackingNumbers) {
    order.trackingNumberUrls = {};
    order.trackingNumbers.forEach(trackingNumber => {
      const matching = SHIPPING_PROVIDERS.find(shippingProvider =>
        !!shippingProvider.regexp.find(regexp => !!trackingNumber.match(regexp)));

      if (matching) {
        order.trackingNumberUrls[trackingNumber] = matching.url.replace('{{trackingNumber}}', trackingNumber);
      }
    });
  }

  return order;
};

export interface OrderProduct {
  productId: number;
  productName: String;
  price: number;
  retailPrice?: number;
  taxAmount?: number;
  taxRate?: number;
  quantity: number;
  cv: number;
  pv: number;
  discountAmount?: number;
  discountCode?: string;
  product: Product;
  errors?: string[];
}

export interface Payment {
  save: boolean;
  paymentProfileId?: number;
  isCreditCard: boolean;
  isBankAccount: boolean;
  isCash: boolean;
  isFree: boolean;
  isPreferred: boolean;
  cardType?: string;
  cardNumber?: string;
  accountNumber?: string;
}

export interface CreditCard extends Payment {
  cardType: string;
  cardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  securityCode: string;
}

export interface CreditCardType {
  name: string;
  regex: RegExp;
}

export interface BankAccount extends Payment {
  accountNumber: string;
  routingNumber: string;
}

export interface Shipping {
  name: string;
  provider: string;
  serviceType: string;
  price: number;
  deliveryDate: any;
  verificationCode: string;
  discountAmount: number;
  discountCode: string;
}

export const deserializeShipping = (shipping: Shipping): Shipping => {
  if (shipping.deliveryDate) {
    shipping.deliveryDate = moment(shipping.deliveryDate).toDate();
  }

  return shipping;
};

export interface ShippingOptions {
  flatShippingPrice: number;
  options: Shipping[];
}

export const deserializeShippingOptions = (shippingOptions: ShippingOptions): ShippingOptions => {
  shippingOptions.options.forEach(shipping => deserializeShipping(shipping));
  return shippingOptions;
};

export interface Subscription {
  productId: number;
  product: Product;
  accountId: number;
  quantity: number;
  payment: Payment;
}

export interface SalesTax {
  retailPrice: number;
  taxAmount: number;
  taxRate: number;
}

export interface OrderHistory extends Order {
  user: User;
  time: any;
  action: string;
}

export const deserializeOrderHistory = (history: OrderHistory): OrderHistory => {
  deserializeOrder(history);
  history.time = moment(history.time).toDate();
  return history;
};
