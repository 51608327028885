import {Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ProductPickerModalComponent} from './product-picker-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Product, ProductFilter} from './product';

@Component({
  selector: 'vni-product-picker',
  templateUrl: './product-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductPickerComponent),
      multi: true
    }
  ]
})
export class ProductPickerComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() required: boolean;
  @Input() filter: ProductFilter;
  @Input() accountId: number;
  @Input() includeInactive: boolean;

  _value: Product;

  constructor(
    private dialog: MatDialog) {
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  get value(): Product {
    return this._value;
  }

  set value(value: Product) {
    this._value = value;

    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    this.value = value;
  }

  selectProduct() {
    const dialogRef = this.dialog.open(ProductPickerModalComponent);
    dialogRef.componentInstance.filter = this.filter;
    dialogRef.componentInstance.accountId = this.accountId;
    dialogRef.componentInstance.includeInactive = this.includeInactive;

    const subscription = dialogRef.afterClosed().subscribe(user => {
      if (user) {
        this.value = user;
      }
      subscription.unsubscribe();
    });
  }
}
