import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';

import {TermsAndConditionsDialogComponent} from './terms-and-conditions-dialog.component';

import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {TermsAndConditionsComponent} from '@vni/common/terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [
    TermsAndConditionsDialogComponent,
    TermsAndConditionsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    TermsAndConditionsDialogComponent
  ]
})
export class VniTermsAndConditionsModule {
}
