import { Routes } from '@angular/router';
import { ErrorPageComponent } from './error-page/error-page.component';

export const ROUTES: Routes = [
  { path: 'backoffice', loadChildren: () => import('./backoffice/vni-backoffice.module').then(m => m.VniBackofficeModule) },

  {
    path: 'forbidden',
    component: ErrorPageComponent,
    data: { title: '403 - Forbidden', message: 'Error.ForbiddenError' }
  },

  { path: '', redirectTo: 'new', pathMatch: 'full' },
  { path: 'new', loadChildren: () => import('./new/vni-new.module').then(m => m.VniNewModule) },
  { path: ':accountUrl', redirectTo: 'new/:accountUrl', pathMatch: 'prefix' },

  {
    path: '**',
    component: ErrorPageComponent,
    data: { title: '404 - Page Not Found', message: 'Error.PageNotFoundError' }
  }
];
