import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxMaskModule} from 'ngx-mask';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';

import {FpPipesModule} from '@fp/common';

import {VniUserModule} from '../users/user.module';
import {VniAccountModule} from '@vni/common/accounts/account.module';

import {CartComponent} from './cart.component';
import {CheckoutDialogComponent} from './checkout-dialog.component';
import {CheckoutAccountComponent} from './checkout-account.component';
import {CheckoutShippingComponent} from './checkout-shipping.component';
import {VniPaymentModule} from '../payment/payment.module';
import {CheckoutSummaryComponent} from './checkout-summary.component';
import {CheckoutPriceEditorComponent} from './checkout-price-editor.component';
import {CheckoutAddressComponent} from './checkout-address.component';

import {SubscriptionDialogComponent} from './subscription-dialog.component';
import {ProductSubscriptionDialogComponent} from './product-subscription-dialog.component';
import {ProductOptionsBottomSheetComponent} from './product-options-bottom-sheet.component';
import {CreateSubscriptionOrderDialogComponent} from './create-subscription-order-dialog.component';

import {VniProductModule} from '../products/product.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(),
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatStepperModule,
    MatDividerModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatBottomSheetModule,
    MatListModule,
    FpPipesModule,
    VniUserModule,
    VniAccountModule,
    VniProductModule,
    VniPaymentModule
  ],
  declarations: [
    CartComponent,
    CheckoutDialogComponent,
    CheckoutAccountComponent,
    CheckoutShippingComponent,
    CheckoutSummaryComponent,
    CheckoutPriceEditorComponent,
    CheckoutAddressComponent,
    SubscriptionDialogComponent,
    ProductSubscriptionDialogComponent,
    ProductOptionsBottomSheetComponent,
    CreateSubscriptionOrderDialogComponent
  ],
  exports: [
    CartComponent,
    CheckoutPriceEditorComponent
  ]
})
export class VniShoppingModule {
}
