import { Component, Input } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Product } from '../products/product';

@Component({
  selector: 'vni-product-options-bottom-sheet',
  templateUrl: 'product-options-bottom-sheet.component.html'
})
export class ProductOptionsBottomSheetComponent {
  @Input() product: Product;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ProductOptionsBottomSheetComponent>) { }

  select(option: Product) { }

  onSelect(option: Product, event: MouseEvent): void {
    this.select(option);
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
