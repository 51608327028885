import * as moment from 'moment';
import {deserializeOrder, Order} from './shopping/order';
import {Account, Rank} from '@vni/common/accounts/account';

export interface CommissionType {
  key: string;
  name: string;
}

export interface Commission {
  id: number;
  runId: number;
  time?: any;
  start?: any;
  end?: any;
  type: CommissionType;
  amount: number;
  accountId: number;
  accountName: string;
  orders?: Order[];
  unilevelLevel?: number;
  unilevelPercent?: number;
  rmbRank?: Rank;
  ldbRank?: Rank;
  ldbPartner?: Account;
  mapShares?: number;

  deleting?: boolean;
}

export const deserializeCommission = (commission: Commission) => {
  commission.time = moment(commission.time).toDate();
  commission.start = moment(commission.start).toDate();
  commission.end = moment(commission.end).toDate();

  if (commission.orders) {
    commission.orders.forEach(order => deserializeOrder(order));
  }

  return commission;
};

export interface Promotion {
  accountId: number;
  accountName: string;
  accountType: string;

  pv: number;
  gv: number;
  cv: number;
  hcpv: number;
  qv: number;
  ambassadorCount: number;
  subscriptionPv: number;
  subscriptionOrderPv: number;

  previousEarnedRank: Rank;
  newEarnedRank: Rank;
  previousTopEarnedRank: Rank;
  newTopEarnedRank: Rank;
  previousRank: Rank;
  newRank: Rank;
  previousTopRank: Rank;
  newTopRank: Rank;

  previousFounderShares: number;
  newFounderShares: number;
  previousFounderViolation: any;
  previousEliteShares: number;
  newEliteShares: number;
  previousEliteViolation: any;
  previousMapShares: number;
  newMapShares: number;
  mapAccounts: [{
    accountId: number;
    accountName: string;
  }];

  sponsoredPreferredCustomerCount: number;

  rankBoostOrderId: number;
}

export const deserializePromotion = (promotion: Promotion) => {
  if (promotion.previousFounderViolation) {
    promotion.previousFounderViolation = moment(promotion.previousFounderViolation).toDate();
  }
};

export interface PayoutBatch {
  id: number;
  date: any;
  isVoid: boolean;
}

export const deserializePayoutBatch = (batch: PayoutBatch) => {
  batch.date = moment(batch.date).toDate();
};

export interface CommissionRun {
  id: number;
  type: string;
  state: string;
  time: any;
  start: any;
  end: any;
  mid: any;
  commissionCount: number;
  total: number;
  promotionCount: number;
  totalCv: number;

  commissions: Commission[];
  promotions: Promotion[];
  payoutBatches: PayoutBatch[];

  totals: any;
  account: Account;

  locked: boolean;
}

export const deserializeCommissionRun = (run: CommissionRun) => {
  run.time = moment(run.time).toDate();
  run.start = moment(run.start).toDate();
  run.end = moment(run.end).toDate();

  if (run.commissions) {
    run.commissions.forEach(commission => deserializeCommission(commission));
  }

  if (run.promotions) {
    run.promotions.forEach(promotion => deserializePromotion(promotion));
  }

  if (run.payoutBatches) {
    run.payoutBatches.forEach(batch => deserializePayoutBatch(batch));
  }

  return run;
};
