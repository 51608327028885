import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from '../users/auth.service';
import {PricingType, Product, ProductInventoryItem, ProductPricing} from './product';
import {lastValueFrom, Observable, Observer} from 'rxjs';
import {Tag} from '../tags/tag';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    private productUrl = 'rest/product';

    constructor(
        private http: HttpClient,
        private auth: AuthService) {
    }

    async loadProducts(
        pricingType: PricingType,
        search?: string,
        page?: number,
        edit?: boolean,
        filter?: any,
        accountId?: number): Promise<Product[]> {
        let params = new HttpParams()
            .set('pricingType', PricingType[pricingType])
            .set('edit', String(edit));

        if (page != null) {
            params = params.set('page', page.toString(10));
        }
        if (search != null) {
            params = params.set('search', search);
        }
        if (filter != null) {
            params = params.set('filter', JSON.stringify(filter));
        }
        if (accountId != null) {
            params = params.set('accountId', accountId.toString(10));
        }

        const o = this.http.get<Product[]>(this.productUrl, {params});
        return lastValueFrom(o);
    }

    async loadProductDetails(productId: number, pricingType: PricingType): Promise<Product> {
        const params = new HttpParams().set('pricingType', PricingType[pricingType]);
        const options = {params: params};

        const o = this.http.get<Product>(`${this.productUrl}/${productId}`, options);
        return lastValueFrom(o);
    }

    async addProduct(newProduct: any): Promise<number> {
        const o = this.http.post(this.productUrl, newProduct);
        return +await lastValueFrom(o);
    }

    async discontinue(productId: number): Promise<void> {
        const o = this.http.delete<void>(`${this.productUrl}/${productId}`);
        return lastValueFrom(o);
    }

    uploadFile(productId: number, type: string, file: File, pricingType: PricingType): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);

        return new Observable((observer: Observer<any>) => {
            const xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);

                observer.next(progress);
            };

            xhr.open('POST', `${this.productUrl}/${productId}/file/${type}`, true);

            const headers = this.auth.headers();
            headers.keys().forEach(key => xhr.setRequestHeader(key, headers.get(key)));
            xhr.setRequestHeader('pricingType', pricingType);

            xhr.send(formData);
        });
    }

    async deleteFile(productId: number, type: string): Promise<void> {
        const o = this.http.delete<void>(`${this.productUrl}/${productId}/file/${type}`);
        return lastValueFrom(o);
    }

    async updateValue(productId: number, field: string, content: string): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/value/${field}`, content);
        return lastValueFrom(o);
    }

    async updateActive(productId: number, isActive: boolean): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/isActive`, isActive);
        return lastValueFrom(o);
    }

    async updateIsBundle(productId: number, isBundle: boolean): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/isBundle`, isBundle);
        return lastValueFrom(o);
    }

    async updateBundleProductNum(productId: number, bundleProductNum: number): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/bundleProductNum`, bundleProductNum || null);
        return lastValueFrom(o);
    }

    async updateBundleMinAmount(productId: number, bundleMinAmount: number): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/bundleMinAmount`, bundleMinAmount || null);
        return lastValueFrom(o);
    }

    async updateHasOptions(productId: number, hasOptions: boolean): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/hasOptions`, hasOptions);
        return lastValueFrom(o);
    }

    async updateOptionsTitle(productId: number, optionsTitle: string): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/optionsTitle`, optionsTitle);
        return lastValueFrom(o);
    }

    async addOption(productId: number, optionId, optionName: string): Promise<Product[]> {
        const o = this.http.post<Product[]>(`${this.productUrl}/${productId}/option/${optionId}`, optionName);
        return lastValueFrom(o);
    }

    async deleteOption(productId: number, optionId: number): Promise<Product[]> {
        const o = this.http.delete<Product[]>(`${this.productUrl}/${productId}/option/${optionId}`);
        return lastValueFrom(o);
    }

    async updateSortPosition(productId: number, sortPosition: number): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/sortPosition`, sortPosition);
        return lastValueFrom(o);
    }

    async updateVisibility(productId: number, visibility: any): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/visibility`, visibility);
        return lastValueFrom(o);
    }

    async loadPricing(productId: number): Promise<Map<string, ProductPricing>> {
        const o = this.http.get<Map<string, ProductPricing>>(`${this.productUrl}/${productId}/pricing`);
        return lastValueFrom(o);
    }

    async updatePricing(productId: number, pricing: ProductPricing): Promise<void> {
        const o = this.http.put<void>(`${this.productUrl}/${productId}/pricing`, pricing);
        return lastValueFrom(o);
    }

    async addInventoryItem(productId: number, inventoryItemId: number, quantity: number): Promise<ProductInventoryItem[]> {
        const o = this.http.put<ProductInventoryItem[]>(`${this.productUrl}/${productId}/item/${inventoryItemId}`, quantity);
        return lastValueFrom(o);
    }

    async deleteInventoryItem(productId: number, inventoryItemId: number): Promise<ProductInventoryItem[]> {
        const o = this.http.delete<ProductInventoryItem[]>(`${this.productUrl}/${productId}/item/${inventoryItemId}`);
        return lastValueFrom(o);
    }

    async addBundleProduct(productId: number, bundleProductId: number): Promise<Product[]> {
        const o = this.http.post<Product[]>(`${this.productUrl}/${productId}/bundleProduct/${bundleProductId}`, null);
        return lastValueFrom(o);
    }

    async deleteBundleProduct(productId: number, bundleProductId: number): Promise<Product[]> {
        const o = this.http.delete<Product[]>(`${this.productUrl}/${productId}/bundleProduct/${bundleProductId}`);
        return lastValueFrom(o);
    }

    async loadTags(productId: number): Promise<Tag[]> {
        const o = this.http.get<Tag[]>(`${this.productUrl}/${productId}/tag`);
        return lastValueFrom(o);
    }

    async updateTags(productId: number, tags: Tag[]): Promise<void> {
        const o = this.http.post<void>(`${this.productUrl}/${productId}/tag`, tags);
        return lastValueFrom(o);
    }

    async setYouTubeVideo(productId: number, youtube: any): Promise<string> {
        const o = this.http.post(`${this.productUrl}/${productId}/youtube`, youtube,
            {responseType: 'text'});
        return lastValueFrom(o);
    }
}
