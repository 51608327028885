import { Order, OrderProduct } from './order';
import { User } from '../users/user';
import * as moment from 'moment';

export const RMA_OPEN_REASONS = [
  'TOO_MUCH_PRODUCT',
  'CANNOT_AFFORD',
  'CHANGED_MIND',
  'PRODUCT_EXCHANGE',
  'RESULTS',
  'DISLIKE_TASTE',
  'REACTION',
  'SUBSCRIPTION_OR_TIMING',
  'DAMAGED',
  'EXPIRED',
  'OTHER'];

export class RMA {
  id: number;
  number: string;
  orderId: number;
  status: string;

  openDate: any;
  openBy: User;
  openReason: string;
  openComment: string;

  receiveDate: any;
  receiveBy: User;
  receiveComment: string;

  cancelDate: any;
  cancelBy: User;
  cancelComment: string;

  amount: number;
  taxAmount: number;
  pv: number;
  cv: number;

  products: RMAProduct[];
  rmaTotals: RMATotals;

  public static deserialize(rma: RMA): RMA {
    rma.openDate = moment(rma.openDate).toDate();

    if (rma.receiveDate) {
      rma.receiveDate = moment(rma.receiveDate).toDate();
    }

    if (rma.cancelDate) {
      rma.cancelDate = moment(rma.cancelDate).toDate();
    }

    return rma;
  }
}

export class RMAProduct {
  productId: number;
  orderProduct: OrderProduct;
  returnQuantity: number;
  receiveQuantity: number;
}

export class RMAUpdate {
  rma: RMA;
  order: Order;
}

export class RMATotals {
  rmaOpenCount: number;
  rmaReceivedCount: number;
  returnedAmount: number;
  returnedTaxAmount: number;
  rpv: number;
  rcv: number;
}
