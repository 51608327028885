import {Component, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {checkAccountUrl} from '@vni/common/accounts/account-url.validator';
import {PaymentComponent} from '../payment/payment.component';
import {AccountService} from '@vni/common/accounts/account.service';
import {AuthService} from './auth.service';
import {Subscription} from 'rxjs';
import {UrlService} from '@vni/common';

@Component({
  selector: 'vni-ambassador-registration-form',
  templateUrl: './ambassador-registration-form.component.html',
  styleUrls: ['./ambassador-registration-form.component.scss']
})
export class AmbassadorRegistrationFormComponent implements OnChanges, OnDestroy {
  @Input() countryIsoCode = 'US';

  public ambassadorFormGroup: FormGroup;

  public payment: PaymentComponent;

  currentUserSubscription: Subscription;

  @ViewChild('payment', {static: false}) set setPayment(theElementRef: PaymentComponent) {
    this.payment = theElementRef;
  }

  constructor(auth: AuthService,
              accountService: AccountService,
              public url: UrlService) {

    this.ambassadorFormGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      url: new FormControl(null, Validators.required, checkAccountUrl(accountService)),
      tinType: new FormControl(null, Validators.required),
      tin: new FormControl(null, Validators.required),
      registrationInterval: new FormControl(null, Validators.required)
    });

    this.currentUserSubscription = auth.currentUser
      .subscribe(currentUser => {
        if (currentUser?.account?.isAffiliate) {
          this.ambassadorFormGroup.controls.name.disable();
          this.ambassadorFormGroup.controls.url.disable();

          this.ambassadorFormGroup.controls.name.setValue(currentUser.account.name);
          this.ambassadorFormGroup.controls.url.setValue(currentUser.account.url);
        }
      });
  }

  ngOnChanges() {
    if (this.countryIsoCode === 'CA') {
      this.ambassadorFormGroup.controls.tinType.setValue('SIN');
    } else {
      this.ambassadorFormGroup.controls.tinType.setValue(null);
    }
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  get invalid() {
    return this.ambassadorFormGroup.invalid || this.payment.invalid || this.ambassadorFormGroup.pending;
  }

  get value() {
    return {...this.ambassadorFormGroup.value, payment: {...this.payment.value}};
  }
}
