import {Address} from '../address';
import {Account, deserializeAccount} from '@vni/common/accounts/account';
import {TranslateService} from '@ngx-translate/core';

export class User {
  userName: string;
  password: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  company: string;
  displayName: string;
  authToken: string;

  roles: string[];
  isAdmin: boolean;
  isDealer: boolean;
  isConsumer: boolean;
  isCustomerService: boolean;
  isAccounting: boolean;

  address: Address;

  email: string;
  phone: string;
  receiveOffers: boolean;

  account: Account;
  parent: Account;

  archived: boolean;

  static deserialize(user: User, translate: TranslateService) {
    if (user.account) {
      deserializeAccount(user.account, translate);
    }

    return user;
  }
}


export const USER_ROLES = ['users', 'admins', 'dealers', 'customer_service', 'accounting'];
