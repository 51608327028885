import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnDestroy {
  title: string;
  message: string;

  private dataSubscription: Subscription;

  constructor(
    public router: Router,
    private route: ActivatedRoute) {

    this.dataSubscription = route.data.subscribe(data => {
      this.title = data.title;
      this.message = data.message;
    });
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
