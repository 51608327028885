import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccountPickerModalComponent } from './account-picker-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Account, ALL_ACCOUNTS } from './account';

@Component({
  selector: 'vni-account-picker',
  templateUrl: './account-picker.component.html',
  styleUrls: ['./account-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountPickerComponent),
      multi: true
    }
  ]
})
export class AccountPickerComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() hint: string;
  @Input() required: boolean;
  @Input() disabled = false;
  @Input() include: Account;
  @Input() exclude: Account;
  @Input() includeSystem: boolean;
  @Input() maxLevel: number;
  @Input() showAll = false;

  _value: Account;

  constructor(
    private dialog: MatDialog) { }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  get value(): Account {
    return this._value;
  }

  set value(value: Account) {
    this._value = value;

    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    if (value && value.id === -9999) {
      this.value = ALL_ACCOUNTS;
    } else {
      this.value = value;
    }
  }

  selectAccount() {
    const dialogRef = this.dialog.open(AccountPickerModalComponent);
    dialogRef.componentInstance.include = this.include;
    dialogRef.componentInstance.exclude = this.exclude;
    dialogRef.componentInstance.includeSystem = this.includeSystem;
    dialogRef.componentInstance.maxLevel = this.maxLevel;
    dialogRef.componentInstance.showAll = this.showAll;

    const subscription = dialogRef.afterClosed().subscribe(account => {
      if (account) {
        this.value = account;
      }
      subscription.unsubscribe();
    });
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
