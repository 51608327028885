import {Component, Input, ViewChild, OnInit, OnDestroy, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Order} from '../shopping/order';
import {User} from '../users/user';
import {Account} from '@vni/common/accounts/account';
import {AuthService} from '../users/auth.service';
import {OrderService} from '../shopping/order.service';
import {ErrorHandler} from '../error-handler.service';
import {Subscription} from 'rxjs';
import {MatStepper} from '@angular/material/stepper';
import {CheckoutAccountComponent} from './checkout-account.component';
import {CheckoutAddressComponent} from './checkout-address.component';
import {CheckoutShippingComponent} from './checkout-shipping.component';
import {PaymentComponent} from '../payment/payment.component';
import {CheckoutSummaryComponent} from './checkout-summary.component';
import {CheckoutPriceEditorComponent} from './checkout-price-editor.component';
import {Payment} from '../shopping/order';
import {PricingType} from '../products/product';
import {DialogService} from '../dialog.service';
import {DOCUMENT} from '@angular/common';

@Component({
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.scss']
})
export class CheckoutDialogComponent implements OnInit, OnDestroy {
  @Input() retail = false;
  order = {} as Order;

  cartSubscription: Subscription;
  hasSubscribable: boolean;

  currentUser: User;
  currentUserSubscription: Subscription;

  submitted = false;

  showStepTimeout: any;

  public stepper: MatStepper;

  @ViewChild('stepper', {static: false}) set setStepper(theElementRef: MatStepper) {
    this.stepper = theElementRef;
  }

  public account: CheckoutAccountComponent;

  @ViewChild('account', {static: false}) set setAccount(theElementRef: CheckoutAccountComponent) {
    this.account = theElementRef;
  }

  public address: CheckoutAddressComponent;

  @ViewChild('address', {static: false}) set setAddress(theElementRef: CheckoutAddressComponent) {
    this.address = theElementRef;
  }

  public shipping: CheckoutShippingComponent;

  @ViewChild('shipping', {static: false}) set setShipping(theElementRef: CheckoutShippingComponent) {
    this.shipping = theElementRef;
  }

  public payment: PaymentComponent;

  @ViewChild('payment', {static: false}) set setPayment(theElementRef: PaymentComponent) {
    this.payment = theElementRef;
  }

  public prices: CheckoutPriceEditorComponent;

  @ViewChild('prices', {static: false}) set setPrices(theElementRef: CheckoutPriceEditorComponent) {
    this.prices = theElementRef;
  }

  public summary: CheckoutSummaryComponent;

  @ViewChild('summary', {static: false}) set setSummary(theElementRef: CheckoutSummaryComponent) {
    this.summary = theElementRef;
  }

  constructor(
    public dialogRef: MatDialogRef<CheckoutDialogComponent>,
    private orderService: OrderService,
    public auth: AuthService,
    private errorHandler: ErrorHandler,
    public dialogService: DialogService,
    @Inject(DOCUMENT) private document: any) {

    this.currentUserSubscription = this.auth.currentUser
      .subscribe(currentUser => this.currentUser = currentUser);
  }

  ngOnInit() {
    this.cartSubscription = this.orderService.cart.subscribe(cart => {
      this.order.products = JSON.parse(JSON.stringify(cart)).items;
      this.hasSubscribable = this.order.products.some(orderProduct => orderProduct.product.isSubscribable);
      if (!this.hasSubscribable) {
        this.order.subscribe = false;
      }
    });
    setTimeout(() => this.summary.refresh(), 500);
  }

  ngOnDestroy() {
    this.cartSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }

  get complete() {
    return this.orderService.complete(this.order);
  }

  placeOrder() {
    if (!this.isFree) {
      this.order.payment = this.payment.value;
    }

    this.order.shipping = this.shipping.value;

    this.submitted = true;

    const order = JSON.parse(JSON.stringify(this.order));

    this.orderService.submit(this.order)
      .then(orderId => {
        this.submitted = false;
        this.order = order;
        this.order.id = orderId;
      }).catch(error => {
      this.submitted = false;

      if (error.status === 400) {
        this.errorHandler.handle('Error.ProcessingPayment', error);
        setTimeout(() => this.stepper.selectedIndex = 3);
      } else if (error.status === 402) {
        this.errorHandler.handle('Error.PaymentMissing', error);
      } else if (error.status === 403) {
        this.errorHandler.handle('Error.AccountFraud', error);
      } else {
        this.errorHandler.handle('Error.PlacingOrder', error);
      }
    });
  }

  get refreshingCart() {
    return this.orderService.refreshingCart;
  }

  accountChanged(account: Account) {
    this.order = {} as Order;
    this.order.buyingAccountId = null;
    this.order.shipping = null;
    this.order.payment = null;

    this.order.buyingAccount = account;
    this.order.buyingAccountId = account ? account.id : null;
    this.order.shippingAddress = account ? account.shippingAddress : null;

    let pricingType = this.retail ? PricingType.RETAIL : PricingType.WHOLESALE;
    if (this.currentUser && this.currentUser.isDealer) {
      pricingType = PricingType.WHOLESALE;
    }
    this.orderService.refreshCart(pricingType, this.order.buyingAccountId).then(items => this.order.products = items);

    if (this.shipping) {
      this.shipping.value = null;
    }

    if (this.payment) {
      this.payment.setValue('-1');
    }

    setTimeout(() => this.summary.refresh(), 500);
  }

  get isFree() {
    return this.orderService.total(this.order) === 0;
  }

  selectStep(e: any) {
    const SHIPPING_ADDRESS_INDEX = 1;
    const SHIPPING_INDEX = 2;
    const PAYMENT_INDEX = 3;
    const SUMMARY_INDEX = this.currentUser.isAdmin ? 5 : 4;


    switch (e.selectedIndex) {
      case SHIPPING_ADDRESS_INDEX:
        this.order.shipping = null;
        break;
      case SHIPPING_INDEX:
        this.order.shippingAddress = this.address.value;

        setTimeout(() => this.summary.refresh(), 500);
        this.shipping.load();
        break;
      case PAYMENT_INDEX:
        this.order.shipping = this.shipping.value;

        if (this.isFree) {
          this.order.payment = {isFree: true} as Payment;

          e.previouslySelectedIndex < e.selectedIndex ?
            setTimeout(() => this.stepper.next()) :
            setTimeout(() => this.stepper.previous());
        }
        break;
      case SUMMARY_INDEX:
        if (this.payment) {
          this.order.payment = this.payment.value;
        }
    }

    setTimeout(() => this.summary.refresh(), 500);
    this.reposition(e.selectedIndex);
  }

  reposition(selectedIndex?: number) {
    clearTimeout(this.showStepTimeout);
    this.showStepTimeout = setTimeout(() => {
      this.document.getElementById('checkout-step-' + (selectedIndex != null ? selectedIndex : this.stepper.selectedIndex))
        .parentElement.parentElement.scrollIntoView({
        behavior: 'smooth', block: 'start', inline:
          'nearest'
      });
    }, 500);
  }
}
