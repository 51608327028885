import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './auth.service';
import { ErrorHandler } from '../error-handler.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { passwordFormat, areEqual } from '@fp/common';

@Component({
  templateUrl: './password-reset-dialog.component.html'
})
export class PasswordResetDialogComponent implements OnInit {
  @Input() passwordResetToken: string;

  private _inProgress = false;

  passwordFormGroup: FormGroup;
  credFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PasswordResetDialogComponent>,
    private auth: AuthService,
    private errorHandler: ErrorHandler,
    private translate: TranslateService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    if (!this.passwordResetToken) {
      this.dialogRef.close();
    } else {
      this.passwordFormGroup = new FormGroup({
        password: new FormControl(null, [Validators.required, passwordFormat]),
        confirmPassword: new FormControl(null, [Validators.required, areEqual])
      });

      this.credFormGroup = new FormGroup({
        passwordResetToken: new FormControl(this.passwordResetToken, Validators.required),
        passwordConfirm: this.passwordFormGroup
      });
    }
  }

  get inProgress() {
    return this._inProgress;
  }

  resetPassword() {
    this._inProgress = true;

    this.auth.resetPassword(this.credFormGroup.value)
      .then(() => {
        this._inProgress = false;

        this.snackBar.open(this.translate.instant('PasswordResetSuccessMessage'), this.translate.instant('DISMISS'), {
          duration: 10000,
        });

        this.dialogRef.close();
      }).catch(error => {
        this._inProgress = false;
        this.errorHandler.handle('Error.UnableToResetPassword', error);
      });
  }
}
