import {Address} from '../address';
import {User} from '../users/user';
import {Product} from '../products/product';
import {Subscription} from '../shopping/order';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {CommissionType} from '../commission';

export const METHODS_OF_COMMUNICATION = ['EMAIL', 'PHONE', 'MAIL'];
export const PAYOUT_METHODS = ['CHECK', 'PAY_QUICKER'];

export const PREFERRED_CONTACT_TIMES = ['ANY_TIME', 'MORNING', 'AFTERNOON'];

export enum AccountType {
  CUSTOMER = 'CUSTOMER',
  AMBASSADOR = 'AMBASSADOR',
  AFFILIATE = 'AFFILIATE',
  RESELLER = 'RESELLER',
  SYSTEM = 'SYSTEM'
}

export enum RegistrationInterval {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL'
}

export const REGISTRATION_INTERVALS = [
  RegistrationInterval.MONTHLY,
  RegistrationInterval.ANNUAL
];

export const ALL_ACCOUNTS = {
  id: -9999,
  name: 'All'
} as Account;

export interface AccountVolumeInterval {
  start: Date;
  end: Date;
  mid: Date;
}

export const deserializeAccountVolumeInterval = (interval: AccountVolumeInterval): AccountVolumeInterval => {
  interval.start = moment(interval.start).toDate();
  interval.end = moment(interval.end).toDate();
  interval.mid = new Date((interval.start.getTime() + interval.end.getTime()) / 2);

  return interval;
};

export interface AccountVolume extends AccountVolumeInterval {
  mid: Date;
  pv: number;
  cv: number;
  gv: number;
  fsgv: number;
  totalGV: number;
  hcpv: number;

  rpv: number;
  rcv: number;
  rgv: number;

  subscriptionOrderPv: number;

  ambassadorCount: number;
  customerCount: number;
  qualifiedLegCount: number;
  isQualifiedLeg: boolean;
  isVipCustomer: boolean;
  sponsoredPreferredCustomerCount: number;
  activeSponsoredPartnerCount: number;

  lastUpdated?: Date;

  qv: number;
  rank: Rank;

  qualifiedLegs?: Account[];
  actionableLegs?: Account[];
}

export const deserializeAccountVolume = (volume: AccountVolume): AccountVolume => {
  deserializeAccountVolumeInterval(volume);
  volume.lastUpdated = moment(volume.lastUpdated).toDate();

  return volume;
};

export interface SubscriptionRewardsLevel {
  index: number;
  name: string;
  priceDiscount: number;
  shippingDiscount: number;
  mapShareBoost: number;
}

export interface SubscriptionRewards {
  subscriptionAvgPV: number;
  level: SubscriptionRewardsLevel;
}

export interface Account {
  id: number;
  name: string;
  title: string;
  url: string;
  accountImgUrl: string;
  webGreeting: string;
  retailOnly: boolean;

  emailPrimary: string;
  emailSecondary: string;
  phonePrimary: string;
  phoneSecondary: string;
  fax: string;

  billingAddress: Address;
  shippingAddress: Address;

  preferredMethodOfCommunication: string;
  payoutMethod: string;

  sponsorId: number;
  sponsor: Account;

  parentId: number;
  parent: Account;
  childCount: number;
  children: Account[];
  users: User[];

  rank: Rank;
  topRank: Rank;

  registrationDate: Date;
  partnershipDate: Date;
  moveUntil: Date;
  canMove: boolean;

  tin: string;
  tinType: string;

  subscriptionDate: number;
  subscriptionNextDate: any;
  subscriptionInterval: number;

  anetPreferredPaymentProfileId: string;

  taxExempt: boolean;
  taxExemptionNumber: string;

  volumes: AccountVolume[];

  lastOrderDate: any;
  ambassadorCount: number;
  affiliateCount: number;
  customerCount: number;

  hasSubscriptions: boolean;
  subscriptionRewards: SubscriptionRewards;
  subscriptions: Subscription[];

  type: string;
  isCustomer: boolean;
  isAmbassador: boolean;
  isAffiliate: boolean;
  isReseller: boolean;
  isVipCustomer: boolean;
  isLegacyVipCustomer: boolean;
  isArchived: boolean;
  isSystem: boolean;

  founderShares: number;
  eliteShares: number;
  mapShares: number;

  products: Product[];
  registrationRenewalDate: Date;
  registrationInterval: RegistrationInterval;

  shopifyId: number;
}

export const deserializeAccount = (account: Account, translate: TranslateService): Account => {
  switch (account.type) {
    case 'AMBASSADOR':
      account.title = account.rank.index ? account.rank.name : translate.instant('AccountType.AMBASSADOR');
      break;
    case 'AFFILIATE':
      account.title = translate.instant('AccountType.AFFILIATE');
      break;
    case 'CUSTOMER':
      account.title = account.isVipCustomer ? 'VIP ' : '';
      account.title += translate.instant('AccountType.CUSTOMER');
      break;
    default:
      account.title = translate.instant('AccountType.' + account.type);
  }

  if (account.registrationDate) {
    account.registrationDate = moment(account.registrationDate).toDate();
  }

  if (account.partnershipDate) {
    account.partnershipDate = moment(account.partnershipDate).toDate();
  }

  if (account.registrationRenewalDate) {
    account.registrationRenewalDate = moment(account.registrationRenewalDate).toDate();
  }

  if (account.moveUntil) {
    account.moveUntil = moment(account.moveUntil).toDate();
  }

  if (account.lastOrderDate) {
    account.lastOrderDate = moment(account.lastOrderDate).toDate();
  }

  if (account.subscriptionNextDate) {
    account.subscriptionNextDate = moment(account.subscriptionNextDate).toDate();
  }

  if (account.volumes) {
    account.volumes.forEach(volume => deserializeAccountVolume(volume));
  }

  if (account.registrationRenewalDate) {
    account.registrationRenewalDate = moment(account.registrationRenewalDate).toDate();
  }

  return account;
};

export interface Rank {
  index: number;
  name: string;
  pv?: number;
  gv?: number;
  maxVolumePerLeg?: number;
  sponsoredPartners?: number;
  maxSponsoredPartnersPerLeg?: number;
  sponsoredCustomers?: number;
  commissions: CommissionType[];
}

export interface Lead {
  name: string;
  phone: string;
  email: string;
}

export interface AccountNote {
  id: number;
  userName: string;
  userDisplayName: string;
  createTime: any;
  editTime: any;
  note: string;
}

export const deserializeAccountNote = (note: AccountNote): AccountNote => {
  note.createTime = moment(note.createTime).toDate();

  if (note.editTime) {
    note.editTime = moment(note.editTime).toDate();
  }

  return note;
};

export interface AccountHistory extends Account {
  sponsorName: string;
  parentName: string;

  accountHistoryId: number;
  user: User;
  time: any;
  action: string;
}

export const deserializeAccountHistory = (history: AccountHistory, translate: TranslateService): AccountHistory => {
  deserializeAccount(history, translate);
  history.time = moment(history.time).toDate();
  return history;
};

export interface AccountBonuses {
  matchAwards: Date[];
  rankMaintenanceBonuses: {
    rank: Rank;
    earnedCount: number;
    totalCount: number;
    qualifyingCycles: number;
    lastEarnedOn: Date;
    availableUntil: Date;
    isExpired: boolean;
    isFullyEarned: boolean;
    isPending: boolean;
  }[];
  eliteBonusPool: {
    shareCount: number;
    qualifiedMonthCount: number;
  };
  mapPool: {
    shareFraction: number;
    shareCount: number;
    estimatedSponsoredCount: number;
    estimatedShareFraction: number;
    estimatedShareCount: number;
    additionalShareCount: number;
    earnedShareCount: number;
    remainingSponsoredCount: number;
  };
}

export const deserializeAccountBonuses = (accountBonuses: AccountBonuses): AccountBonuses => {
  accountBonuses.matchAwards = accountBonuses.matchAwards.map(awardTime => moment(awardTime).toDate());

  accountBonuses.rankMaintenanceBonuses.forEach(rmb => {
    rmb.lastEarnedOn = rmb.lastEarnedOn ? moment(rmb.lastEarnedOn).toDate() : null;
    rmb.availableUntil = rmb.availableUntil ? moment(rmb.availableUntil).toDate() : null;
  });

  return accountBonuses;
};
