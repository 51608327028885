import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ErrorPageComponent } from './error-page.component';

import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    ErrorPageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,

    MatIconModule
  ]
})
export class VniErrorPageModule { }
