import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {User} from './user';
import {AccountType, METHODS_OF_COMMUNICATION} from '@vni/common/accounts/account';
import {areEqual, Country, GeoService, passwordFormat} from '@fp/common';
import {ErrorHandler} from '../error-handler.service';
import {UserService} from './user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AccountService} from '@vni/common/accounts/account.service';
import {checkUserName} from './user-name.validator';
import {TranslateService} from '@ngx-translate/core';
import {MatStepper} from '@angular/material/stepper';
import {AuthService} from '../users/auth.service';
import {AmbassadorRegistrationFormComponent} from './ambassador-registration-form.component';
import {TermsAndConditionsDialogComponent} from '../terms-and-conditions/terms-and-conditions-dialog.component';

import {DOCUMENT} from '@angular/common';
import {ResellerRegistrationFormComponent} from '@vni/common/users/reseller-registration-form.component';

@Component({
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss']
})
export class RegistrationDialogComponent implements OnInit, OnDestroy {
  @Input() type: AccountType;

  protected readonly AccountType = AccountType;

  passwordFormGroup: FormGroup;
  credFormGroup: FormGroup;
  persFormGroup: FormGroup;
  billingAddrFormGroup: FormGroup;
  shippingAddrFormGroup: FormGroup;

  public submitted = false;
  countries: Map<String, Country>;

  billingSelectedCountry: Country;
  billingCountrySubscription: Subscription;

  shippingSelectedCountry: Country;
  shippingCountrySubscription: Subscription;

  sameAsShippingSubscription: Subscription;

  newUser: User;

  currentUser: User;
  currentUserSubscription: Subscription;

  private showStepTimeout: any;

  public stepper: MatStepper;

  @ViewChild('stepper', {static: false}) set setStepper(theElementRef: MatStepper) {
    this.stepper = theElementRef;
  }

  public ambassadorRegistration: AmbassadorRegistrationFormComponent;
  public resellerRegistration: ResellerRegistrationFormComponent;

  @ViewChild('ambassadorRegistration', {static: false}) set setAmbassadorRegistration(theElementRef: AmbassadorRegistrationFormComponent) {
    this.ambassadorRegistration = theElementRef;
  }

  @ViewChild('resellerRegistration', {static: false}) set setResellerRegistration(theElementRef: ResellerRegistrationFormComponent) {
    this.resellerRegistration = theElementRef;
  }

  constructor(
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
    private errorHandler: ErrorHandler,
    private userService: UserService,
    private geoService: GeoService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public auth: AuthService,
    @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit() {
    this.passwordFormGroup = new FormGroup({
      password: new FormControl(null, [Validators.required, passwordFormat]),
      confirmPassword: new FormControl(null, [Validators.required, areEqual])
    });

    this.credFormGroup = new FormGroup({
      sponsor: new FormControl(this.accountService.currentAccount || {id: 0, name: this.translate.instant('Corporate')}),
      userName: new FormControl(null, Validators.required, checkUserName(this.userService)),
      passwordConfirm: this.passwordFormGroup
    });

    this.persFormGroup = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      emailPrimary: new FormControl(null, Validators.required),
      emailSecondary: new FormControl(null),
      phonePrimary: new FormControl(null, Validators.required),
      phoneSecondary: new FormControl(null),
      preferredMethodOfCommunication: new FormControl('EMAIL', Validators.required),
      receiveOffers: new FormControl(this.type !== AccountType.RESELLER)
    });

    if (this.type === AccountType.RESELLER) {
      this.persFormGroup.controls['receiveOffers'].disable();
    }

    this.billingAddrFormGroup = new FormGroup({
      sameAsShipping: new FormControl(false),
      street: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      regionIsoCode: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      countryIsoCode: new FormControl(null, Validators.required)
    });

    this.billingAddrFormGroup.controls.regionIsoCode.disable();
    this.billingAddrFormGroup.controls.postalCode.disable();

    this.shippingAddrFormGroup = new FormGroup({
      street: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      regionIsoCode: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      countryIsoCode: new FormControl(null, Validators.required)
    });

    this.shippingAddrFormGroup.controls.regionIsoCode.disable();
    this.shippingAddrFormGroup.controls.postalCode.disable();

    this.geoService.getCountries().then(countries => {
      this.countries = countries;
    });

    this.shippingCountrySubscription = this.shippingAddrFormGroup.controls.countryIsoCode.valueChanges.subscribe(countryIsoCode => {
      if (this.shippingSelectedCountry && this.shippingSelectedCountry.isoCode === countryIsoCode) {
        return;
      }

      this.shippingSelectedCountry = this.countries[countryIsoCode];
      this.shippingAddrFormGroup.controls.regionIsoCode.setValue(null);

      if (this.shippingSelectedCountry) {
        this.geoService.loadRegions(this.shippingSelectedCountry)
          .then(regions => {
            regions.length > 0 ?
              this.shippingAddrFormGroup.controls.regionIsoCode.enable() :
              this.shippingAddrFormGroup.controls.regionIsoCode.disable();
          });
      } else {
        this.shippingAddrFormGroup.controls.regionIsoCode.disable();
      }

      if (!this.shippingSelectedCountry
        || !this.shippingSelectedCountry.postalCodeType
        || this.shippingSelectedCountry.postalCodeType === 'NULL') {
        this.shippingAddrFormGroup.controls.postalCode.disable();
      } else {
        this.shippingAddrFormGroup.controls.postalCode.enable();
      }
    });

    this.billingCountrySubscription = this.billingAddrFormGroup.controls.countryIsoCode.valueChanges.subscribe(countryIsoCode => {
      if (this.billingSelectedCountry && this.billingSelectedCountry.isoCode === countryIsoCode) {
        return;
      }

      this.billingSelectedCountry = this.countries[countryIsoCode];
      this.billingAddrFormGroup.controls.regionIsoCode.setValue(null);

      if (this.billingSelectedCountry) {
        this.geoService.loadRegions(this.billingSelectedCountry)
          .then(regions => {
            regions.length > 0 && !this.billingAddrFormGroup.value.sameAsShipping ?
              this.billingAddrFormGroup.controls.regionIsoCode.enable() :
              this.billingAddrFormGroup.controls.regionIsoCode.disable();
          });
      } else {
        this.billingAddrFormGroup.controls.regionIsoCode.disable();
      }

      if (!this.billingSelectedCountry
        || !this.billingSelectedCountry.postalCodeType
        || this.billingSelectedCountry.postalCodeType === 'NULL') {
        this.billingAddrFormGroup.controls.postalCode.disable();
      } else if (!this.billingAddrFormGroup.value.sameAsShipping) {
        this.billingAddrFormGroup.controls.postalCode.enable();
      }
    });

    this.sameAsShippingSubscription = this.billingAddrFormGroup.controls.sameAsShipping.valueChanges.subscribe(sameAsShipping => {
      if (sameAsShipping) {
        this.billingAddrFormGroup.controls.street.setValue(this.shippingAddrFormGroup.value.street);
        this.billingAddrFormGroup.controls.city.setValue(this.shippingAddrFormGroup.value.city);
        this.billingAddrFormGroup.controls.countryIsoCode.setValue(this.shippingAddrFormGroup.value.countryIsoCode);

        setTimeout(() => {
          this.billingAddrFormGroup.controls.regionIsoCode.setValue(this.shippingAddrFormGroup.value.regionIsoCode);
          this.billingAddrFormGroup.controls.postalCode.setValue(this.shippingAddrFormGroup.value.postalCode);
        });

        this.billingAddrFormGroup.controls.street.disable();
        this.billingAddrFormGroup.controls.city.disable();
        this.billingAddrFormGroup.controls.regionIsoCode.disable();
        this.billingAddrFormGroup.controls.postalCode.disable();
        this.billingAddrFormGroup.controls.countryIsoCode.disable();
      } else {
        this.billingAddrFormGroup.controls.street.enable();
        this.billingAddrFormGroup.controls.city.enable();
        this.billingAddrFormGroup.controls.regionIsoCode.enable();
        this.billingAddrFormGroup.controls.postalCode.enable();
        this.billingAddrFormGroup.controls.countryIsoCode.enable();
      }
    });

    this.currentUserSubscription = this.auth.currentUser
      .subscribe(currentUser => {
        this.currentUser = currentUser;

        if (currentUser && currentUser.isDealer) {
          this.credFormGroup.controls.sponsor.setValue(currentUser.account);
        }
      });
  }

  ngOnDestroy() {
    this.billingCountrySubscription.unsubscribe();
    this.shippingCountrySubscription.unsubscribe();
    this.sameAsShippingSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }

  get methodsOfCommunication() {
    return METHODS_OF_COMMUNICATION;
  }

  get invalid() {
    const partnerFormInvalid = !this.ambassadorRegistration || this.ambassadorRegistration.invalid;
    const resellerFormInvalid = !this.resellerRegistration || this.resellerRegistration.invalid;

    return this.credFormGroup.invalid
      || this.credFormGroup.pending
      || this.persFormGroup.invalid
      || this.billingAddrFormGroup.invalid
      || this.shippingAddrFormGroup.invalid
      || (this.type === AccountType.AMBASSADOR && partnerFormInvalid)
      || (this.type === AccountType.RESELLER && resellerFormInvalid);
  }

  showAcceptTerms() {
    if (this.type === AccountType.CUSTOMER || this.type === AccountType.RESELLER) {
      this.register();
    } else {
      const dialogRef = this.dialog.open(TermsAndConditionsDialogComponent);
      dialogRef.componentInstance.accountType = this.type;
      const subscription = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.register();
        }
        subscription.unsubscribe();
      });
    }
  }

  async register() {
    this.submitted = true;

    let newReg = {...this.credFormGroup.value};
    newReg.sponsorId = newReg.sponsor ? newReg.sponsor.id : 0;
    delete newReg.sponsor;
    newReg = {...newReg, ...this.persFormGroup.value};

    if (this.ambassadorRegistration && !this.ambassadorRegistration.invalid) {
      newReg.ambassador = this.ambassadorRegistration.value;
    }

    if (this.resellerRegistration && !this.resellerRegistration.invalid) {
      newReg.reseller = this.resellerRegistration.value;
    }

    newReg.shippingAddress = {...this.shippingAddrFormGroup.value};
    newReg.billingAddress = this.billingAddrFormGroup.value.sameAsShipping
      ? newReg.shippingAddress : {...this.billingAddrFormGroup.value};

    try {
      const newUser = await this.userService.register(newReg);
      this.dialogRef.close(newUser);
    } catch (error) {
      if (error.status === 409) {
        await this.errorHandler.handle('Error.UserAlreadyExists', error);
      } else if (error.status === 400) {
        await this.errorHandler.handle('Error.ProcessingPayment', error);
        setTimeout(() => this.stepper.selectedIndex = 3);
      } else {
        await this.errorHandler.handle('Error.Registering', error);
      }
    } finally {
      this.submitted = false;
    }
  }

  stepChanged(e) {
    clearTimeout(this.showStepTimeout);
    this.showStepTimeout = setTimeout(() => {
      this.document.getElementById('reg-step-' + e.selectedIndex)
        .parentElement.parentElement.scrollIntoView({
        behavior: 'smooth', block: 'start', inline:
          'nearest'
      });
    }, 500);
  }
}
