import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {PaymentComponent} from './payment.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,

    TranslateModule,
    FlexLayoutModule,

    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule
  ],
  declarations: [
    PaymentComponent
  ],
  exports: [
    PaymentComponent
  ]
})
export class VniPaymentModule {
}
