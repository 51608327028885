import {Component, Input} from '@angular/core';
import {Order, CreditCard, BankAccount, SalesTax, Subscription} from '../shopping/order';
import {OrderService, CreditCardService} from '../shopping/order.service';

@Component({
  selector: 'vni-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss']
})
export class CheckoutSummaryComponent {
  @Input() order: Order;
  @Input() subscription = false;
  @Input() subscriptions: Subscription[];

  cardType: string;
  maskedCardNumber: string;
  cardExpiration: string;
  tax: SalesTax;

  maskedAccountNumber: string;
  maskedRoutingNumber: string;

  subtotal: number;
  shipping: number;
  taxAmount: number;
  taxRate: number;
  retailPrice: number;
  total: number;
  cv: number;
  pv: number;
  totalDiscountAmount: number;

  isCreditCard: boolean;
  isBankAccount: boolean;
  isCash: boolean;
  isFree: boolean;

  constructor(
    public orderService: OrderService,
    private creditCardService: CreditCardService) {
  }

  public refresh() {
    if (this.order.payment) {
      this.refreshPayment();
    }

    if (this.order.shippingAddress) {
      this.orderService.loadSalesTax(this.order).then(tax => {
        this.tax = tax;
        this.refreshTotals();
      });
    } else {
      this.tax = null;
      this.refreshTotals();
    }
  }

  private refreshPayment() {
    if (this.order.payment.isCreditCard) {
      this.isCreditCard = true;
      const creditCard = this.order.payment as CreditCard;
      this.cardType = creditCard.cardType || this.creditCardService.getType(creditCard.cardNumber).name;
      this.maskedCardNumber = this.creditCardService.getMaskedCardNumber(creditCard.cardNumber);
      this.cardExpiration = (creditCard.expirationMonth || '**') + '/' + (creditCard.expirationYear || '**');
    } else if (this.order.payment.isBankAccount) {
      this.isBankAccount = true;
      const bankAccount = this.order.payment as BankAccount;
      this.maskedAccountNumber = this.creditCardService.getMaskedCardNumber(bankAccount.accountNumber);
      this.maskedRoutingNumber = this.creditCardService.getMaskedCardNumber(bankAccount.routingNumber);
    } else if (this.order.payment.isCash) {
      this.isCash = true;
    } else if (this.order.payment.isFree) {
      this.isFree = true;
    }
  }

  private refreshTotals() {
    this.subtotal = this.orderService.subtotal(this.order.products, this.subscription);
    this.shipping = this.order.shipping ? this.order.shipping.price : 0;
    this.cv = this.orderService.cv(this.order.products);
    this.pv = this.orderService.pv(this.order.products);
    this.totalDiscountAmount = this.orderService.totalDiscount(this.order);

    if (this.tax) {
      this.taxAmount = this.tax.taxAmount;
      this.taxRate = this.tax.taxRate;
      this.retailPrice = this.tax.retailPrice;
      this.total = this.orderService.total(this.order, this.subscription) + this.tax.taxAmount;
    } else {
      this.taxAmount = 0;
      this.taxRate = 0;
      this.retailPrice = this.subtotal;
      this.total = this.orderService.total(this.order, this.subscription);
    }
  }

  get products() {
    return this.order.products?.filter(item => !this.subscription || item.product.isSubscribable);
  }
}
