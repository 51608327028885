import {Injectable, OnDestroy} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from '../users/auth.service';
import {
  Cart,
  CreditCardType,
  deserializeOrder, deserializeOrderHistory, deserializeShippingOptions,
  Order,
  OrderHistory,
  OrderProduct,
  Payment,
  SalesTax,
  ShippingOptions,
  Subscription
} from './order';
import {RMA, RMAProduct} from './rma';
import {Address} from '../address';
import {PricingType, Product} from '../products/product';
import {ProductService} from '../products/product.service';
import {Store} from '@ngrx/store';
import {BehaviorSubject, lastValueFrom, Observable, Observer, Subscription as RxJsSubscription} from 'rxjs';
import {AccountService} from '@vni/common/accounts/account.service';
import {CREDIT_CARD_TYPES} from '@fp/common';
import {ErrorHandler} from '../error-handler.service';
import {TranslateService} from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import {SET_CART} from '../reducers';
import {User} from '../users/user';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ProductOptionsBottomSheetComponent} from './product-options-bottom-sheet.component';

@Injectable({
  providedIn: 'root'
})
export class OrderService implements OnDestroy {
  private orderUrl = 'rest/order';
  private accountUrl = 'rest/account';

  private _cart: Cart;
  private _cartSubscription: RxJsSubscription;

  private _refreshingCart = false;

  private orderPlacedSubject: BehaviorSubject<number> = new BehaviorSubject(null);

  private currentUser: User;
  private _currentUserSubscription: RxJsSubscription;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private store: Store<any>,
    private accountService: AccountService,
    private productService: ProductService,
    private errorHandler: ErrorHandler,
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe,
    private bottomSheet: MatBottomSheet) {

    this._cartSubscription = this.cart.subscribe(cart => this._cart = cart);
    this._currentUserSubscription = this.auth.currentUser.subscribe(currentUser => this.currentUser = currentUser);

    try {
      const strCart = localStorage.getItem('cart');
      if (strCart) {
        const cart = JSON.parse(strCart);
        const items = new Array<OrderProduct>();

        for (const item of cart.items) {
          if (item.productId) {
            items.push(item);
          }
        }

        this.setCart(cart);
      } else {
        this.setCart({
          items: []
        });
      }
    } catch (e) {
      this.setCart({
        items: []
      });
      console.error(e);
    }
  }

  ngOnDestroy() {
    this._cartSubscription.unsubscribe();
    this._currentUserSubscription.unsubscribe();
  }

  get cart(): Observable<Cart> {
    return this.store.select('cart');
  }

  validateBundle(cart: Cart) {
    const bundle = this.findBundle();

    if (bundle && bundle.product.bundleProducts) {
      let prodNum = 0;
      let amount = 0;

      bundle.product.bundleProducts.forEach(product => {
        const cartItem = cart.items.find(item => item.productId === product.id);
        if (cartItem) {
          prodNum += cartItem.quantity;
          amount += cartItem.price * cartItem.quantity;
        }
      });

      const errors = new Array<string>();

      if (bundle.product.bundleProductNum && prodNum < bundle.product.bundleProductNum) {
        const moreNum = bundle.product.bundleProductNum - prodNum;
        errors.push(this.translate.instant('Error.BundleProductNumRequirement', {
          bundleName: bundle.productName,
          productNum: bundle.product.bundleProductNum,
          moreNum: moreNum
        }));
      }

      if (bundle.product.bundleMinAmount && amount < bundle.product.bundleMinAmount) {
        const moreAmount = bundle.product.bundleMinAmount - amount;
        errors.push(this.translate.instant('Error.BundleAmountRequirement', {
          bundleName: bundle.productName,
          amount: this.currencyPipe.transform(bundle.product.bundleMinAmount, '$'),
          moreAmount: this.currencyPipe.transform(moreAmount, '$')
        }));
      }

      bundle.errors = errors.length > 0 ? errors : null;
    }
  }

  public setCart(cart: Cart) {
    this.validateBundle(cart);

    this.store.dispatch({
      type: SET_CART,
      payload: cart
    });
  }

  showOptions(product: Product) {
    const sheetRef = this.bottomSheet.open(ProductOptionsBottomSheetComponent);
    sheetRef.instance.product = product;
    sheetRef.instance.select = (option: Product) => this.addToCart(option);
  }

  addToCart(product: Product) {
    if (product.isBundle && this.findBundle()) {
      this.errorHandler.handle('Error.MoreThanOneBundle');
      return;
    }

    if (product.isPrepurchase && this.isPurchase) {
      this.errorHandler.handle('Error.OrderNotPrepurchase');
      return;
    }

    if (!product.isPrepurchase && this.isPrepurchase) {
      this.errorHandler.handle('Error.OrderIsPrepurchase');
      return;
    }

    if (product.hasOptions) {
      this.showOptions(product);
      return;
    }

    if (product.price != null) {
      let item: OrderProduct = this.getCartProduct(product.id);
      if (item && !item.product.isBundle) {
        item.quantity++;
      } else {
        item = {
          productId: product.id,
          productName: product.name,
          price: product.price,
          cv: product.cv,
          pv: product.pv,
          product,
          quantity: 1
        };

        this._cart.items.push(item);
      }

      this.setCart({...this._cart});
    } else {
      this.errorHandler.handle('Error.ProductUnavailable');
    }
  }

  addToCartById(productId: number, pricingType: PricingType) {
    const item = this.getCartProduct(productId);

    if (item) {
      item.quantity++;
      this.setCart({...this._cart});
    } else {
      this.productService.loadProductDetails(productId, pricingType)
        .then(product => this.addToCart(product));
    }
  }

  removeFromCart(productId: number) {
    const index = this._cart.items.findIndex(item => item.productId === productId);
    this._cart.items.splice(index, 1);
    this.setCart({...this._cart});
  }

  async refreshCart(pricingType: PricingType, accountId?: number): Promise<OrderProduct[]> {
    this._refreshingCart = true;

    const cartItems = JSON.parse(JSON.stringify(this._cart.items)) as OrderProduct[];
    cartItems.forEach(item => delete item.product);

    let params = new HttpParams()
      .set('pricingType', PricingType[pricingType]);

    if (accountId) {
      params = params.set('accountId', String(accountId));
    }

    const o = this.http.post<OrderProduct[]>(this.orderUrl + '/refreshCart', cartItems, {params});
    const items = await lastValueFrom(o);
    this._cart.items = items;
    this.setCart({...this._cart});
    this._refreshingCart = false;

    return items;
  }

  get refreshingCart() {
    return this._refreshingCart;
  }

  private getCartProduct(productId: number): OrderProduct {
    return this._cart ? this._cart.items.find(item => item.productId === productId) : null;
  }

  private findBundle(): OrderProduct {
    return this._cart ? this._cart.items.find(item => item.product.isBundle) : null;
  }

  private get isPrepurchase(): boolean {
    return this._cart && this._cart.items.length > 0 && this._cart.items.every(item => item.product.isPrepurchase);
  }

  public get isPurchase(): boolean {
    return this._cart && this._cart.items.length > 0 && this._cart.items.every(item => !item.product.isPrepurchase);
  }

  subtotal(products: OrderProduct[], subscription = false) {
    if (!products) {
      return 0;
    }

    let subtotal = 0;
    products.forEach(item => {
      if (!subscription || item.product.isSubscribable) {
        subtotal += (subscription ? item.product.wholesalePrice : item.price) * item.quantity;
      }
    });

    return subtotal;
  }

  total(order: Order, subscription = false) {
    return this.subtotal(order.products, subscription)
      + (order.shipping ? order.shipping.price : 0)
      + (order.flatShippingPrice || 0)
      - this.totalDiscount(order);
  }

  cv(products: OrderProduct[]) {
    let cv = 0;
    products?.forEach(item => cv += item.cv * item.quantity);
    return cv;
  }

  pv(products: OrderProduct[]) {
    let pv = 0;
    products?.forEach(item => pv += item.pv * item.quantity);
    return pv;
  }

  totalDiscount(order: Order) {
    let discount = 0;
    order.products?.forEach(item => discount += (item.discountAmount || 0) * item.quantity);

    if (order.shipping) {
      discount += order.shipping.discountAmount || 0;
    }

    discount += order.flatShippingDiscountAmount || 0;

    return discount;
  }

  complete(order: Order) {
    return order.products?.length > 0 && order.shipping && order.payment && order.buyingAccountId;
  }

  get itemCount() {
    if (!this._cart) {
      return 0;
    }

    let itemCount = 0;
    this._cart.items.forEach(item => itemCount += item.quantity);

    return itemCount;
  }

  async loadShippingOptions(order: Order): Promise<ShippingOptions> {
    const newOrder = JSON.parse(JSON.stringify(order));
    newOrder.products.forEach(item => delete item.product);

    newOrder.shippingAddress.regionIsoCode = newOrder.shippingAddress.region.isoCode;
    delete newOrder.shippingAddress.region;

    newOrder.shippingAddress.countryIsoCode = newOrder.shippingAddress.country.isoCode;
    delete newOrder.shippingAddress.country;

    const o = this.http.post<ShippingOptions>(this.orderUrl + '/shippingOptions', newOrder);
    const shippingOptions = await lastValueFrom(o);
    return deserializeShippingOptions(shippingOptions);
  }

  async submit(order: Order): Promise<number> {
    const newOrder = JSON.parse(JSON.stringify(order));
    newOrder.products.forEach(item => delete item.product);
    delete newOrder.buyingAccount;

    newOrder.shippingAddress.regionIsoCode = newOrder.shippingAddress.region.isoCode;
    delete newOrder.shippingAddress.region;

    newOrder.shippingAddress.countryIsoCode = newOrder.shippingAddress.country.isoCode;
    delete newOrder.shippingAddress.country;

    const o = this.http.post<{ orderId: number }>(this.orderUrl, order);
    const orderId = (await lastValueFrom(o)).orderId;

    this.setCart({
      items: []
    });
    this.orderPlacedSubject.next(orderId);
    return orderId;
  }

  async subscribe(order: Order): Promise<{ subscriptions: Subscription[], orderId: number }> {
    const newOrder = JSON.parse(JSON.stringify(order));
    newOrder.products.forEach(item => delete item.product);
    delete newOrder.buyingAccount;

    const o = this.http.post<{ subscriptions: Subscription[], orderId: number }>(
      `${this.accountUrl}/${order.buyingAccountId}/subscriptions`, order);
    const subscriptionResult = await lastValueFrom(o);

    this.setCart({
      items: []
    });

    if (subscriptionResult.orderId) {
      this.orderPlacedSubject.next(subscriptionResult.orderId);
    }

    if (this.currentUser.account && this.currentUser.account.id === order.buyingAccountId) {
      this.accountService.setCurrentSubscriptions(subscriptionResult.subscriptions);
    }

    return subscriptionResult;
  }

  get orderPlaced(): BehaviorSubject<number> {
    return this.orderPlacedSubject;
  }

  async updatePayment(orderId: number, payment: Payment): Promise<Order> {
    const o = this.http.put<Order>(`${this.orderUrl}/${orderId}/payment`, payment);
    const order = await lastValueFrom(o);
    return deserializeOrder(order);
  }

  async loadOrders(search?: string, page?: number): Promise<Order[]> {
    let params = new HttpParams();

    if (page != null) {
      params = params.set('page', page.toString(10));
    }
    if (search != null) {
      params = params.set('search', search);
    }

    const o = this.http.get<Order[]>(this.orderUrl, {params});
    const orders = await lastValueFrom(o);
    return orders.map(order => deserializeOrder(order));
  }

  async loadOrderDetails(orderId: number): Promise<Order> {
    const o = this.http.get<Order>(`${this.orderUrl}/${orderId}`);
    const order = await lastValueFrom(o);
    return deserializeOrder(order);
  }

  async ship(orderId: number, trackingNumbers: string): Promise<Order> {
    const o = this.http.put<Order>(`${this.orderUrl}/${orderId}/ship`, trackingNumbers);
    const order = await lastValueFrom(o);
    return deserializeOrder(order);
  }

  async sendToFulfillment(orderId: number): Promise<Order> {
    const o = this.http.put<Order>(`${this.orderUrl}/${orderId}/sendToFulfillment`, null);
    const order = await lastValueFrom(o);
    return deserializeOrder(order);
  }

  async updateDate(orderId: number, date: string): Promise<void> {
    const o = this.http.put<void>(`${this.orderUrl}/${orderId}/updateDate`, date);
    return lastValueFrom(o);
  }

  async updateStatus(orderId: number, status: string): Promise<void> {
    const o = this.http.put<void>(`${this.orderUrl}/${orderId}/updateStatus`, status);
    return lastValueFrom(o);
  }

  async updateIsSubscription(orderId: number, isSubscription: boolean): Promise<void> {
    const o = this.http.put<void>(`${this.orderUrl}/${orderId}/updateIsSubscription`, isSubscription);
    return lastValueFrom(o);
  }

  async updateShippingAddress(orderId: number, address: Address): Promise<void> {
    const o = this.http.put<void>(`${this.orderUrl}/${orderId}/updateShippingAddress`, address);
    return lastValueFrom(o);
  }

  async updatePrices(orderId: number, order: Order): Promise<Order> {
    const o = this.http.put<Order>(`${this.orderUrl}/${orderId}/prices`, order);
    const updatedOrder = await lastValueFrom(o);
    return deserializeOrder(updatedOrder);
  }

  async cancelOrder(orderId: number): Promise<void> {
    const o = this.http.delete<void>(`${this.orderUrl}/${orderId}`);
    return lastValueFrom(o);
  }

  async readyToShipCount(): Promise<number> {
    const o = this.http.get<{ count: number }>(`${this.orderUrl}/readyToShip`);
    return (await lastValueFrom(o)).count;
  }

  async downloadPackingSlips(fcId: number): Promise<void> {
    const o = this.http.get(`${this.orderUrl}/downloadPackingSlips/${fcId}`, {responseType: 'blob'});
    const res = await lastValueFrom(o);
    FileSaver.saveAs(res, 'packing-slips.pdf');
  }

  async downloadOrder(orderId: number): Promise<void> {
    const o = this.http.get(`${this.orderUrl}/downloadOrder/${orderId}`, {responseType: 'blob'});
    const res = await lastValueFrom(o);
    FileSaver.saveAs(res, 'order' + orderId + '.pdf');
  }

  async downloadExport(fcId: number): Promise<void> {
    const o = this.http.get(`${this.orderUrl}/downloadExportCsv/${fcId}`, {responseType: 'blob'});
    const res = await lastValueFrom(o);
    FileSaver.saveAs(res, 'export.txt');
  }

  uploadTracking(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);

    return new Observable((observer: Observer<any>) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);

        observer.next(progress);
      };

      xhr.open('POST', `${this.orderUrl}/uploadTracking`, true);

      const headers = this.auth.headers();
      headers.keys().forEach(key => xhr.setRequestHeader(key, headers.get(key)));

      xhr.send(formData);
    });
  }

  async loadSalesTax(order: Order): Promise<SalesTax> {
    const newOrder = JSON.parse(JSON.stringify(order));
    newOrder.products?.forEach(item => delete item.product);

    newOrder.shippingAddress.regionIsoCode = newOrder.shippingAddress.region.isoCode;
    delete newOrder.shippingAddress.region;

    newOrder.shippingAddress.countryIsoCode = newOrder.shippingAddress.country.isoCode;
    delete newOrder.shippingAddress.country;

    const params = new HttpParams().set('order', JSON.stringify(newOrder));
    const options = {params};

    const o = this.http.get<SalesTax>(`${this.orderUrl}/salesTax`, options);
    return lastValueFrom(o);
  }

  async loadOrderHistory(orderId: number): Promise<OrderHistory[]> {
    const o = this.http.get<OrderHistory[]>(`${this.orderUrl}/${orderId}/history`);
    const history = await lastValueFrom(o);
    return history.map(item => deserializeOrderHistory(item));
  }

  async loadReturnableProducts(orderId: number): Promise<RMAProduct[]> {
    const o = this.http.get<RMAProduct[]>(`${this.orderUrl}/${orderId}/returnableProduct`);
    const returnableProducts = await lastValueFrom(o);
    returnableProducts.forEach(returnProduct => returnProduct.returnQuantity = 0);
    return returnableProducts;
  }

  async openRMA(orderId: number, rma: RMA): Promise<RMA> {
    const o = this.http.post<RMA>(`${this.orderUrl}/${orderId}/rma`, rma);
    const order = await lastValueFrom(o);
    return RMA.deserialize(order);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {

  getType(cardNumber: string): CreditCardType {
    for (const cardType of CREDIT_CARD_TYPES) {
      if (cardNumber.match(cardType.regex)) {
        return cardType;
      }
    }
  }

  getMaskedCardNumber(cardNumber: string): string {
    let maskedCardNumber = '';
    for (let i = 0; i < cardNumber.length; i++) {
      if (i > cardNumber.length - 5) {
        maskedCardNumber += cardNumber.charAt(i);
      } else {
        maskedCardNumber += '*';
      }
    }

    return maskedCardNumber;
  }
}
