import {Country} from '@fp/common';
import {Address} from '../address';
import {Tag} from '../tags/tag';

export interface ProductDetails {
    gettingTheMost: {
        title: string,
        content: string
    }[];
    science: {
        title: string,
        content: string
    }[];
    faq: {
        question: string;
        answer: string;
    }[];
}

export interface Product {
    id: number;
    name: string;
    title: string;
    caption: string;
    price: number;
    wholesalePrice: number;
    cv: number;
    pv: number;
    description: string;
    ingredients: string;
    science: string;
    faq: string;
    details: ProductDetails;
    files: Map<string, string>;
    pricings: Map<string, ProductPricing>;
    inventoryItems: ProductInventoryItem[];
    tags: Tag[];

    isActive: boolean;
    onBackorder: boolean;
    isBundle: boolean;
    isSubscribable: boolean;
    isPrepurchase?: boolean;
    visibility: string;

    hasOptions: boolean;
    optionsTitle: string;
    optionOfId: number;
    optionName: string;
    options: Product[];
    minOptionPrice: number;
    maxOptionPrice: number;
    minOptionWholesalePrice: number;
    maxOptionWholesalePrice: number;

    bundleProductNum: number;
    bundleMinAmount: number;
    bundleProducts: Product[];

    sortPosition: number;

    youtubeVideoId: string;
    youtubeThumbnailIndex: number;
    youtubeThumbnailUrl: string;
}

export enum PricingType {
    RETAIL = 'RETAIL',
    WHOLESALE = 'WHOLESALE'
}

export interface ProductPricing {
    country: Country;
    isActive: boolean;
    onBackorder: boolean;
    retailPrice: number;
    wholesalePrice: number;
    cv: number;
    pv: number;

    flatShipping: boolean;
    flatShippingPrice: number;

    noTax: boolean;
}

export interface InventoryItem {
    id: number;
    code: string;
    name: string;
    weight: number;
    length: number;
    width: number;
    height: number;
    isActive: boolean;
}

export interface ProductInventoryItem {
    item: InventoryItem;
    quantity: number;
}

export interface FulfillmentCenter {
    id: number;
    name: string;
    address: Address;
    readyToShipCount: number;
}

export interface ProductFilter {
    onlySubscribable?: false;
    tags?: Tag[];
}
