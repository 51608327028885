import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagService } from './tag.service';
import { Tag } from './tag';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'vni-tag-picker',
  templateUrl: './tag-picker.component.html',
  styleUrls: ['./tag-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagPickerComponent),
      multi: true
    }
  ]
})
export class TagPickerComponent implements OnInit, ControlValueAccessor {
  @Input() visibility: string;
  @Input() allowEdit = false;
  editing = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  tags: Tag[];
  _value = new Array<Tag>();

  constructor(private tagService: TagService) { }

  ngOnInit() {
    this.tagService.loadTags(this.visibility).then(tags => this.tags = tags);
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  isSelected(tag: Tag) {
    return this.value && this.value.find(t => t.id === tag.id);
  }

  select(tag: Tag) {
    if (!this.value) {
      this.value = new Array<Tag>();
    }

    const tagIndex = this._value.findIndex(t => t.id === tag.id);
    if (tagIndex !== -1) {
      this.value.splice(tagIndex, 1);
    } else {
      this.value.push(tag);
    }

    this.value = this.value.slice();
    this.onChange(this.value);
    this.onTouched();
  }

  get value(): Tag[] {
    return this._value;
  }

  set value(value: Tag[]) {
    this._value = value;

    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    this.value = value;
  }

  add(input: any, tagName: string) {
    tagName = tagName ? tagName.trim() : null;

    if (input) {
      input.value = '';
    }

    if (tagName) {
      this.tagService.addTag(tagName)
        .then(tag => this.tags.push(tag));
    }
  }

  remove(tag: Tag) {
    if (tag) {
      const tagIndex = this.tags.findIndex(t => t.id === tag.id);
      this.tags.splice(tagIndex, 1);

      const selectedTagIndex = this.value.findIndex(t => t.id === tag.id);
      if (selectedTagIndex !== -1) {
        this.value.splice(selectedTagIndex, 1);
        this.value = this.value.slice();
        this.onChange(this.value);
        this.onTouched();
      }

      this.tagService.deleteTag(tag.id);
    }
  }
}
