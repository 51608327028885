import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Store} from '@ngrx/store';

import {User} from './user';
import {Router} from '@angular/router';
import {Subscription, Observable} from 'rxjs';
import * as moment from 'moment';
import {Locale, GeoService} from '@fp/common';
import {SET_CURRENT_USER, CLEAR_CURRENT_USER, SET_COUNTRY_CODE, CLEAR_CURRENT_SUBSCRIPTIONS} from '../reducers';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private authUrl = 'rest/authenticate';
  private _currentUser: User;
  private _countryCode: string;

  private currentUserSubscription: Subscription;
  private countryCodeSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private router: Router,
    private locale: Locale,
    private store: Store<any>,
    private geo: GeoService,
    private translate: TranslateService) {

    this.currentUserSubscription = this.currentUser.subscribe(cu => {
      this._currentUser = cu;

      if (cu) {
        this.setCountryCode(cu.address.country.isoCode);
      }
    });

    this.countryCodeSubscription = this.countryCode.subscribe(cc => this._countryCode = cc);

    try {
      const strCurrentUser = localStorage.getItem('currentUser');
      if (strCurrentUser) {
        this._currentUser = JSON.parse(strCurrentUser);
        setTimeout(() => this.refreshCurrentUser());
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const countryCode = localStorage.getItem('countryCode');
      this.setCountryCode(countryCode || 'US');
    } catch (e) {
      console.error(e);
    }

    if (!this._currentUser) {
      this.geo.loadCountryCode().then(countryCode => {
        if (!this._currentUser) {
          this.setCountryCode(countryCode);
        }
      });
    }
  }

  login(userName: string, password: string): Promise<User> {
    const body = new HttpParams()
      .set('username', userName)
      .set('password', password);

    return this.http.post(this.authUrl, body)
      .toPromise()
      .then(response => response as User)
      .then(cu => {
        User.deserialize(cu, this.translate);
        this.setCurrentUser(cu);
        return cu;
      });
  }

  get currentUser(): Observable<User> {
    return this.store.select('currentUser');
  }

  get authToken(): string {
    return this._currentUser ? this._currentUser.authToken : null;
  }

  get countryCode(): Observable<string> {
    return this.store.select('countryCode');
  }

  setCurrentUser(user: User) {
    this.store.dispatch({
      type: SET_CURRENT_USER,
      payload: user
    });

    this.store.dispatch({
      type: CLEAR_CURRENT_SUBSCRIPTIONS
    });
  }

  setCountryCode(countryCode: string) {
    this.store.dispatch({
      type: SET_COUNTRY_CODE,
      payload: countryCode
    });
  }

  logout() {
    this.store.dispatch({
      type: CLEAR_CURRENT_USER
    });

    this.store.dispatch({
      type: CLEAR_CURRENT_SUBSCRIPTIONS
    });
  }

  headers(): HttpHeaders {
    return new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + this.authToken)
      .set('timeZoneOffset', String(moment().utcOffset() / 60))
      .set('localeCode', this.locale.messageCode)
      .set('countryCode', this._countryCode);
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
    this.countryCodeSubscription.unsubscribe();
  }

  async refreshCurrentUser(): Promise<User> {
    return this.http.get(this.authUrl)
      .toPromise()
      .then(result => result as User)
      .then(cu => {
        User.deserialize(cu, this.translate);
        cu.authToken = this._currentUser.authToken;
        this.setCurrentUser(cu);
        return cu;
      }).catch(() => {
        this.setCurrentUser(null);
        return null;
      });
  }

  async requestPasswordReset(userName: string) {
    return this.http.post(this.authUrl + '/requestPasswordReset', userName).toPromise();
  }

  async resetPassword(passwordReset: any) {
    return this.http.post(this.authUrl + '/resetPassword', passwordReset)
      .toPromise()
      .then(response => response as User)
      .then(cu => {
        User.deserialize(cu, this.translate);
        this.setCurrentUser(cu);
        this.router.navigate(['']);
        return cu;
      });
  }
}
