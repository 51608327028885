import {Component, Input, ViewChild, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AccountService} from '@vni/common/accounts/account.service';
import {Account} from '@vni/common/accounts/account';
import {ErrorHandler} from '../error-handler.service';
import {PaymentComponent} from '../payment/payment.component';
import {Router} from '@angular/router';

@Component({
  templateUrl: './create-subscription-order-dialog.component.html',
  styleUrls: ['./create-subscription-order-dialog.component.scss']
})
export class CreateSubscriptionOrderDialogComponent implements OnInit {
  @Input() accountId: number;

  account: Account;

  isLoading = false;
  submitting = false;

  public payment: PaymentComponent;

  @ViewChild('payment', {static: false}) set setPayment(theElementRef: PaymentComponent) {
    this.payment = theElementRef;
  }

  constructor(
    private accountService: AccountService,
    private errorHandler: ErrorHandler,
    public dialogRef: MatDialogRef<CreateSubscriptionOrderDialogComponent>,
    private router: Router) {

  }

  ngOnInit() {
    this.accountService.loadAccountDetails(this.accountId)
      .then(account => this.account = account);
  }


  createOrder() {
    this.submitting = true;

    const payment = this.payment ? this.payment.value : null;

    this.accountService.createSubscriptionOrder(this.account.id, payment)
      .then(orderId => {
        this.dialogRef.close();
        this.router.navigate(['/backoffice/order/', orderId]);
      }).catch(error => {
      return this.errorHandler.handle('Error.CreatingSubscriptionOrder', error);
    })
      .finally(() => this.submitting = false);
  }
}
