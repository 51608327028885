import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';

import {LoginComponent} from './login.component';
import {LoginDialogComponent} from './login-dialog.component';
import {RegistrationDialogComponent} from './registration-dialog.component';
import {AmbassadorRegistrationFormComponent} from './ambassador-registration-form.component';
import {AmbassadorRegistrationDialogComponent} from './ambassador-registration-dialog.component';
import {PasswordResetRequestDialogComponent} from './password-reset-request-dialog.component';
import {PasswordResetDialogComponent} from './password-reset-dialog.component';

import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';

import {FpPipesModule, FpConfirmationDialogModule} from '@fp/common';
import {VniAccountModule} from '@vni/common/accounts/account.module';
import {VniTermsAndConditionsModule} from '../terms-and-conditions/terms-and-conditions.module';
import {VniPaymentModule} from '../payment/payment.module';
import {ResellerRegistrationFormComponent} from '@vni/common/users/reseller-registration-form.component';
import {ResellerApplicationDialogComponent} from '@vni/common/users/reseller-application-dialog.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    LoginComponent,
    LoginDialogComponent,
    RegistrationDialogComponent,
    AmbassadorRegistrationFormComponent,
    AmbassadorRegistrationDialogComponent,
    ResellerRegistrationFormComponent,
    ResellerApplicationDialogComponent,
    PasswordResetRequestDialogComponent,
    PasswordResetDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDividerModule,
    FpPipesModule,
    FpConfirmationDialogModule,
    VniAccountModule,
    VniTermsAndConditionsModule,
    VniPaymentModule
  ],
  exports: [
    LoginComponent
  ]
})
export class VniUserModule {
}
