import {Component, Input} from '@angular/core';
import {AccountType} from '@vni/common';

@Component({
  selector: 'vni-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  readonly AccountType = AccountType;
  @Input() accountType: AccountType;
}
