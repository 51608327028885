import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LoginDialogComponent} from './users/login-dialog.component';
import {RegistrationDialogComponent} from './users/registration-dialog.component';
import {SubscriptionRewardsDialogComponent} from './subscription-rewards/subscription-rewards-dialog.component';
import {AmbassadorRegistrationDialogComponent} from './users/ambassador-registration-dialog.component';
import {AccountType, Account} from '@vni/common/accounts/account';
import {PasswordResetDialogComponent} from '@vni/common/users/password-reset-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  showLogin(retail?: boolean) {
    const dialogRef = this.dialog.open(LoginDialogComponent);
    dialogRef.componentInstance.retail = retail;
  }

  showRegistration(type: AccountType) {
    const dialogRef = this.dialog.open(RegistrationDialogComponent, {panelClass: 'stepper-container', disableClose: true});
    dialogRef.componentInstance.type = type;
  }

  showBecomeAnAmbassador(account: Account) {
    const dialogRef = this.dialog.open(AmbassadorRegistrationDialogComponent, {panelClass: 'dialog-600px', disableClose: true});
    dialogRef.componentInstance.account = account;
  }

  showSubscriptionRewards(retail?: boolean) {
    const dialogRef = this.dialog.open(SubscriptionRewardsDialogComponent, {panelClass: 'dialog-borderless', width: '65vw'});
    dialogRef.componentInstance.retail = retail;
  }

  showPasswordResetDialog(passwordResetToken: string) {
    const dialogRef = this.dialog.open(PasswordResetDialogComponent, {disableClose: true});
    dialogRef.componentInstance.passwordResetToken = passwordResetToken;
  }
}
