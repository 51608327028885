export interface Country {
  isoCode: string;
  unCode: string;
  name: string;
  regionType: string;
  postalCodeType: string;
  regions: Region[];
}

export interface Region {
  isoCode: string;
  name: string;
  abbrev: string;
  countryIsoCode: string;
}

export interface Address {
  name: string;
  street: string;
  city: string;
  postalCode: string;
  region: Region;
  country: Country;
}

const PO_BOX_REGEX = /^p(ost)?[ |\.]*o(ffice)?[ |\.]*(box)?[ 0-9]*[^[a-z ]]*/i;
export const isPoBox = (address: Address) => !!address?.street?.match(PO_BOX_REGEX);
