import {AsyncValidatorFn, FormControl, ValidationErrors} from '@angular/forms';
import {UserService} from './user.service';

let debouncer: any;

export const checkUserName = (userService: UserService): AsyncValidatorFn =>
  (control: FormControl): Promise<ValidationErrors | null> => {
    clearTimeout(debouncer);

    return new Promise(resolve => {
      debouncer = setTimeout(() => {
        userService.validateUserName(control.value).then(res => {
          return resolve(res.isValid ? null : res.errors);
        });
      }, 500);
    });
  };
