import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Tag} from './tag';
import {lastValueFrom} from 'rxjs';

@Injectable()
export class TagService {
  private tagUrl = 'rest/tag';

  constructor(
    private http: HttpClient) {
  }

  async loadTags(visibility?: string): Promise<Tag[]> {
    let params = new HttpParams();

    if (visibility) {
      params = params.set('visibility', visibility);
    }

    const o = this.http.get<Tag[]>(this.tagUrl, {params});
    return lastValueFrom(o);
  }

  async addTag(tagName: string): Promise<Tag> {
    const o = this.http.post<Tag>(this.tagUrl, tagName);
    return lastValueFrom(o);
  }

  async deleteTag(tagId: number): Promise<void> {
    const o = this.http.delete<void>(this.tagUrl + '/' + tagId);
    return lastValueFrom(o);
  }
}
