import {User} from './users/user';
import {Subscription, Cart} from './shopping/order';
import {Action} from '@ngrx/store';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

export function currentUserReducer(state: User = null, action: Action & { payload?: any }): User {
  switch (action.type) {
    case SET_CURRENT_USER:
      localStorage.setItem('currentUser', JSON.stringify(action.payload));
      return action.payload;
    case CLEAR_CURRENT_USER:
      localStorage.removeItem('currentUser');
      return null;
    default:
      return state;
  }
}

export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const CLEAR_COUNTRY_CODE = 'CLEAR_COUNTRY_CODE';

export function countryCodeReducer(state: string = null, action: Action & { payload?: any }): string {
  switch (action.type) {
    case SET_COUNTRY_CODE:
      localStorage.setItem('countryCode', action.payload);
      return action.payload;
    case CLEAR_COUNTRY_CODE:
      localStorage.removeItem('countryCode');
      return null;
    default:
      return state;
  }
}

export const SET_CURRENT_SUBSCRIPTIONS = 'SET_CURRENT_SUBSCRIPTIONS';
export const CLEAR_CURRENT_SUBSCRIPTIONS = 'CLEAR_CURRENT_SUBSCRIPTIONS';

export function currentSubscriptionsReducer(state: Subscription[] = null, action: Action & { payload?: any }): Subscription[] {
  switch (action.type) {
    case SET_CURRENT_SUBSCRIPTIONS:
      return action.payload;
    case CLEAR_CURRENT_SUBSCRIPTIONS:
      return null;
    default:
      return state;
  }
}

export const SET_CART = 'SET_CART';

export function cartReducer(state: Cart = null, action: Action & { payload?: any }): Cart {
  switch (action.type) {
    case SET_CART:
      localStorage.setItem('cart', JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
}
