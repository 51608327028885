import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {ErrorHandler} from '../error-handler.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Account, ALL_ACCOUNTS} from './account';
import {AccountService} from './account.service';

@Component({
  templateUrl: './account-picker-modal.component.html',
  styleUrls: ['./account-picker-modal.component.scss']
})
export class AccountPickerModalComponent implements OnInit, OnDestroy {
  @Input() include: Account;
  @Input() exclude: Account;
  @Input() includeSystem: boolean;
  @Input() maxLevel: number;
  @Input() showAll = false;

  selectedAccount: Account;

  accountSearch: FormGroup;
  submitted = false;
  accounts: Account[];

  inProgress = false;
  timer: any;
  page = -1;
  hasMorePages = false;

  searchSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<AccountPickerModalComponent>,
    private accountService: AccountService,
    private errorHandler: ErrorHandler) {
  }

  ngOnInit() {
    this.accountSearch = new FormGroup({
      search: new FormControl(null)
    });

    this.searchSubscription = this.accountSearch.valueChanges.subscribe(data => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.loadFirstPage(), 1000);
    });

    this.loadFirstPage();
  }

  loadFirstPage() {
    this.page = -1;
    this.loadNextPage();
  }

  loadNextPage() {
    this.inProgress = true;
    this.page++;

    const includeId = this.include ? this.include.id : null;
    const excludeId = this.exclude ? this.exclude.id : null;
    this.accountService.loadAccounts(this.accountSearch.value.search,
      this.page, this.includeSystem, includeId, excludeId, this.maxLevel)
      .then(accounts => {
        if (this.page === 0) {
          this.accounts = accounts;
        } else {
          this.accounts = this.accounts.concat(accounts);
        }

        this.hasMorePages = accounts.length > 0;
        this.inProgress = false;
      }).catch((error) => {
      this.inProgress = false;
      this.errorHandler.handle('Error.LoadingAccounts', error);
    });
  }

  onScroll(event) {
    const target = event.target;
    const scrollHeight = target.scrollHeight;
    const scrollTop = target.scrollTop;
    const height = target.offsetHeight;

    if (scrollTop + height + 20 > scrollHeight && !this.inProgress && this.hasMorePages) {
      this.loadNextPage();
    }
  }

  select(account: Account) {
    this.dialogRef.close(account);
  }

  selectAll() {
    this.dialogRef.close(ALL_ACCOUNTS);
  }

  isSelected(account: Account): boolean {
    return this.selectedAccount && this.selectedAccount.id === account.id;
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }
}
