import {Component, OnDestroy} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'vni-reseller-registration-form',
  templateUrl: './reseller-registration-form.component.html',
  styleUrls: ['./reseller-registration-form.component.scss']
})
export class ResellerRegistrationFormComponent implements OnDestroy {
  public resellerFormGroup: FormGroup;

  taxExemptSubscription: Subscription;

  constructor() {
    this.resellerFormGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      tin: new FormControl(null, Validators.required),
      taxExempt: new FormControl(false, Validators.required),
      taxExemptionNumber: new FormControl(null, Validators.required),
    });

    this.resellerFormGroup.controls['taxExemptionNumber'].disable();

    this.taxExemptSubscription = this.resellerFormGroup.controls['taxExempt'].valueChanges.subscribe(taxExempt => {
      if (taxExempt) {
        this.resellerFormGroup.controls['taxExemptionNumber'].enable();
      } else {
        this.resellerFormGroup.controls['taxExemptionNumber'].reset();
        this.resellerFormGroup.controls['taxExemptionNumber'].disable();
      }
    });
  }

  ngOnDestroy() {
    this.taxExemptSubscription?.unsubscribe();
  }

  get invalid() {
    return this.resellerFormGroup.invalid;
  }

  get value() {
    return {...this.resellerFormGroup.value};
  }
}
