import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from '../users/auth.service';
import {Action, Store} from '@ngrx/store';
import {Config} from './config';
import {Subscription, Observable, lastValueFrom, debounceTime} from 'rxjs';
import {UrlService} from '@vni/common';

export const SET_CONFIG = 'SET_CONFIG';

export function configReducer(state: Config = null, action: Action & { payload?: any }): Config {
  switch (action.type) {
    case SET_CONFIG:
      return action.payload;
    default:
      return state;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements OnDestroy {
  private configUrl = 'rest/config';
  private _config: Config;
  currentUserSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    urlService: UrlService,
    private store: Store<any>) {

    this.currentUserSubscription = this.auth.currentUser
      .pipe(debounceTime(250))
      .subscribe(async () => {
        this._config = await this.loadConfig();
        this.setConfig(this._config);

        if (this._config) {
          urlService.shopRootUrl = this._config.site.shopUrl;
          urlService.affiliateRootUrl = this._config.site.affiliateUrl;
          urlService.businessRootUrl = this._config.site.businessUrl;
        }
      });
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  async loadConfig(): Promise<Config> {
    const o = this.http.get<Config>(this.configUrl);
    return lastValueFrom(o);
  }

  async refresh() {
    this.setConfig(null);

    const params = new HttpParams().set('refresh', 'true');

    const o = this.http.get<Config>(this.configUrl, {params});
    const config = await lastValueFrom(o);
    this.setConfig(config);
  }

  private setConfig(config: Config) {
    this.store.dispatch({
      type: SET_CONFIG,
      payload: config
    });
  }

  get config(): Observable<Config> {
    return this.store.select('config');
  }

  async updateSiteConfigItem(key: string, value: any): Promise<Object> {
    this._config.site[key].value = value;
    this.setConfig(Object.assign({}, this._config));

    const params = new HttpParams().set('key', key).set('value', value);

    const o = this.http.put(this.configUrl + '/site', null, {params});
    return lastValueFrom(o);
  }
}
