import {AsyncValidatorFn, FormControl, ValidationErrors} from '@angular/forms';
import {AccountService} from './account.service';

let debouncer: any;

export const checkAccountUrl = (accountService: AccountService): AsyncValidatorFn =>
  (control: FormControl | any): Promise<ValidationErrors | null> => {
    clearTimeout(debouncer);

    return new Promise(resolve => {
      debouncer = setTimeout(() => {
        accountService.validateAccountUrl(control.value, control.accountId).then(res => {
          return resolve(res.isValid ? null : res.errors);
        });
      }, 500);
    });
  };
