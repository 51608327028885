import {Component, Input, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {User} from '../users/user';
import {Account} from '@vni/common/accounts/account';
import {AuthService} from '../users/auth.service';
import {OrderService} from '../shopping/order.service';
import {ErrorHandler} from '../error-handler.service';
import {Subscription as Sub} from 'rxjs';
import {MatStepper} from '@angular/material/stepper';
import {CheckoutAccountComponent} from './checkout-account.component';
import {PaymentComponent} from '../payment/payment.component';
import {CheckoutSummaryComponent} from './checkout-summary.component';
import {Order, Subscription} from '../shopping/order';
import {PricingType} from '../products/product';
import {DialogService} from '../dialog.service';

@Component({
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionDialogComponent implements OnInit, OnDestroy {
  @Input() retail = false;
  order = {} as Order;

  cartSubscription: Sub;

  currentUser: User;
  currentUserSubscription: Sub;

  submitted = false;
  subscriptions: Subscription[];

  public stepper: MatStepper;

  @ViewChild('stepper', {static: false}) set setStepper(theElementRef: MatStepper) {
    this.stepper = theElementRef;
  }

  public account: CheckoutAccountComponent;

  @ViewChild('account', {static: false}) set setAccount(theElementRef: CheckoutAccountComponent) {
    this.account = theElementRef;
  }

  public payment: PaymentComponent;

  @ViewChild('payment', {static: false}) set setPayment(theElementRef: PaymentComponent) {
    this.payment = theElementRef;
  }

  public summary: CheckoutSummaryComponent;

  @ViewChild('summary', {static: false}) set setSummary(theElementRef: CheckoutSummaryComponent) {
    this.summary = theElementRef;
  }

  constructor(
    public dialogRef: MatDialogRef<SubscriptionDialogComponent>,
    private orderService: OrderService,
    public auth: AuthService,
    private errorHandler: ErrorHandler,
    public dialogService: DialogService) {

    this.currentUserSubscription = this.auth.currentUser
      .subscribe(currentUser => this.currentUser = currentUser);
  }

  ngOnInit() {
    this.cartSubscription = this.orderService.cart.subscribe(cart => this.order.products = JSON.parse(JSON.stringify(cart)).items);
    setTimeout(() => this.summary.refresh(), 500);
  }

  ngOnDestroy() {
    this.cartSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }

  get complete() {
    return this.order.products.length > 0 && this.payment && !this.payment.invalid && this.order.buyingAccountId;
  }

  subscribe() {
    this.submitted = true;

    this.order.payment = this.payment.value;
    const order = JSON.parse(JSON.stringify(this.order));

    setTimeout(() => this.summary.refresh(), 500);

    this.orderService.subscribe(this.order)
      .then(result => {
        this.submitted = false;
        this.order = order;
        this.order.id = result.orderId;
        this.subscriptions = result.subscriptions;
      }).catch(error => {
      this.submitted = false;

      if (error.status === 400) {
        this.errorHandler.handle('Error.ProcessingPayment', error);
        setTimeout(() => this.stepper.selectedIndex = 3);
      } else if (error.status === 403) {
        this.errorHandler.handle('Error.AccountFraud', error);
      } else {
        this.errorHandler.handle('Error.Subscribing', error);
      }
    });
  }

  get refreshingCart() {
    return this.orderService.refreshingCart;
  }

  accountChanged(account: Account) {
    this.order.buyingAccountId = null;
    this.order.payment = null;

    this.order.buyingAccount = account;
    this.order.buyingAccountId = account ? account.id : null;

    this.order.shippingAddress = account ? account.shippingAddress : null;
    this.order.shipping = null;

    let pricingType = this.retail ? PricingType.RETAIL : PricingType.WHOLESALE;
    if (this.currentUser && this.currentUser.isDealer) {
      pricingType = PricingType.WHOLESALE;
    }

    if (this.payment) {
      this.payment.setValue('-1');
    }

    this.orderService.refreshCart(pricingType, this.order.buyingAccountId)
      .then(items => this.order.products = items.filter(item => item.product.isSubscribable))
      .then(() => {
        if (this.order.shippingAddress) {
          return this.orderService.loadShippingOptions(this.order);
        }
      })
      .then(shippingOptions => {
        if (shippingOptions) {
          this.order.flatShippingPrice = shippingOptions.flatShippingPrice;
        }

        if (shippingOptions && shippingOptions.options.length > 0) {
          this.order.shipping = shippingOptions.options[0];
        }
      })
      .finally(() => {
        this.summary.refresh();
      });
  }

  selectStep(event: any) {
    setTimeout(() => this.summary.refresh(), 500);
  }

  get finished() {
    return this.order.id || this.subscriptions;
  }
}
