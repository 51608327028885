import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {Payment} from '../shopping/order';
import {AccountService} from '@vni/common/accounts/account.service';
import {AuthService} from '../users/auth.service';
import {creditCardFormatValidator} from '@fp/common';
import {ErrorHandler} from '../error-handler.service';

@Component({
  selector: 'vni-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnChanges {
  @Input() accountId: number;
  @Input() showNewBankAccount = false;
  @Input() showCash = false;
  @Input() implicitSave = false;
  @Input() hideSaved = false;

  savedPaymentFormGroup: FormGroup;
  creditCardFormGroup: FormGroup;
  bankAccountFormGroup: FormGroup;
  expirationYears: number[];

  savedPayments: Payment[];
  loadingSavedPayments = false;

  constructor(
    private accountService: AccountService,
    private auth: AuthService,
    private errorHandler: ErrorHandler) {

    this.savedPaymentFormGroup = new FormGroup({
      paymentProfileId: new FormControl('-1', Validators.required)
    });

    this.creditCardFormGroup = new FormGroup({
      cardNumber: new FormControl(null, [Validators.required, creditCardFormatValidator]),
      expirationMonth: new FormControl(null, Validators.required),
      expirationYear: new FormControl(null, Validators.required),
      securityCode: new FormControl(null, Validators.required),
      save: new FormControl(true, Validators.required),
    });

    this.bankAccountFormGroup = new FormGroup({
      accountNumber: new FormControl(null, Validators.required),
      routingNumber: new FormControl(null, Validators.required),
      save: new FormControl(true, Validators.required)
    });

    let year = new Date().getFullYear();
    this.expirationYears = new Array<number>();
    for (let i = 0; i < 20; i++) {
      this.expirationYears.push(year++);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.accountId || changes.hideSaved) && this.accountId && !this.hideSaved) {
      this.loadingSavedPayments = true;
      this.accountService.loadSavedPayments(this.accountId)
        .then(savedPayments => {
          this.savedPayments = savedPayments;

          if (savedPayments.length > 0) {
            const preferredPayment = savedPayments.find(payment => payment.isPreferred);

            this.savedPaymentFormGroup.controls.paymentProfileId.setValue(
              (preferredPayment && preferredPayment.paymentProfileId) || savedPayments[0].paymentProfileId);
          } else {
            this.savedPaymentFormGroup.controls.paymentProfileId.setValue('-1');
          }

          this.loadingSavedPayments = false;
        }).catch(error => {
        this.errorHandler.handle('Error.LoadingSavedPayments', error);
        this.savedPayments = [];
        this.loadingSavedPayments = false;
      });
    } else if ((changes.accountId || changes.hideSaved) && this.hideSaved) {
      this.savedPayments = [];
    }
  }

  public get invalid() {
    return this.loadingSavedPayments
      || (this.savedPaymentFormGroup.value.paymentProfileId === '-1' && this.creditCardFormGroup.invalid)
      || (this.savedPaymentFormGroup.value.paymentProfileId === '-2' && this.bankAccountFormGroup.invalid);
  }

  public get newPayment(): Payment {
    switch (this.savedPaymentFormGroup.value.paymentProfileId) {
      case '-1':
        return {...this.creditCardFormGroup.value, isCreditCard: true};
      case '-2':
        return {...this.bankAccountFormGroup.value, isBankAccount: true};
      case '-3':
        return {isCash: true} as Payment;
      default:
        return null;
    }
  }

  public get savedPayment(): Payment {
    return +this.savedPaymentFormGroup.value.paymentProfileId < 0
      ? null : this.savedPayments.find(savedPayment =>
        savedPayment.paymentProfileId === this.savedPaymentFormGroup.value.paymentProfileId);
  }

  public get value(): Payment {
    return this.savedPayment || this.newPayment;
  }

  public setValue(paymentProfileId: string) {
    this.savedPaymentFormGroup.controls.paymentProfileId.setValue(paymentProfileId);
  }
}
