import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from '../users/auth.service';
import {Banner} from './banner';
import {Observable, Observer} from 'rxjs';

@Injectable()
export class BannerService {
  private bannerUrl = 'rest/banner';

  constructor(
    private http: HttpClient,
    private auth: AuthService) {
  }

  async loadBanners(edit?: boolean): Promise<Banner[]> {
    const params = new HttpParams()
      .set('edit', String(edit));

    return this.http.get(this.bannerUrl, {params}).toPromise()
      .then(result => result as Banner[]);
  }

  async addBanner(): Promise<Banner[]> {
    return this.http.post(this.bannerUrl, null).toPromise()
      .then(result => result as Banner[]);
  }

  async deleteBanner(bannerId: number): Promise<Banner[]> {
    return this.http.delete(this.bannerUrl + '/' + bannerId).toPromise()
      .then(result => result as Banner[]);
  }

  uploadImgFile(bannerId: number, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);

    return new Observable((observer: Observer<any>) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);

        observer.next(progress);
      };

      xhr.open('POST', this.bannerUrl + '/' + bannerId + '/imgFile', true);

      const headers = this.auth.headers();
      headers.keys().forEach(key => xhr.setRequestHeader(key, headers.get(key)));

      xhr.send(formData);
    });
  }

  async deleteImgFile(bannerId: number) {
    return this.http.delete(this.bannerUrl + '/' + bannerId + '/imgFile').toPromise();
  }

  async updateCaption(bannerId: number, caption: string) {
    return this.http.put(this.bannerUrl + '/' + bannerId + '/caption', caption).toPromise();
  }

  async updateImgPosition(bannerId: number, imgPosition: string) {
    return this.http.put(this.bannerUrl + '/' + bannerId + '/imgPosition', imgPosition).toPromise();
  }

  async updateEnabled(bannerId: number, isEnabled: boolean) {
    return this.http.put(this.bannerUrl + '/' + bannerId + '/enabled', String(isEnabled)).toPromise();
  }

  async moveBanner(bannerId: number, direction: string): Promise<Banner[]> {
    return this.http.put(this.bannerUrl + '/' + bannerId + '/move', direction).toPromise()
      .then(result => result as Banner[]);
  }
}
