import { Component } from '@angular/core';
import {ConfigService} from '@vni/common';

@Component({
  selector: 'vni-root',
  templateUrl: './vni.component.html',
})
export class VniComponent {
  constructor(configService: ConfigService) {
  }
}
