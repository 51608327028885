import {Component, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Order, ShippingOptions, Shipping} from '../shopping/order';
import {OrderService} from '../shopping/order.service';
import {Subscription} from 'rxjs';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {isPoBox} from '../address';

@Component({
  selector: 'vni-checkout-shipping',
  templateUrl: './checkout-shipping.component.html',
  styleUrls: ['./checkout-shipping.component.scss']
})
export class CheckoutShippingComponent implements OnDestroy {
  @Input() order: Order;
  @Input() accountId: number;
  @Output() change = new EventEmitter();

  shippingOptions: ShippingOptions;
  shippingFormGroup: FormGroup;

  shippingOptionsTimer: any;
  isLoading = false;

  shippingSubscription: Subscription;

  constructor(
    private orderService: OrderService) {

    this.shippingFormGroup = new FormGroup({
      shipping: new FormControl(null, Validators.required),
    });

    this.shippingSubscription = this.shippingFormGroup.controls.shipping.valueChanges.subscribe(shipping => this.change.emit(shipping));
  }

  ngOnDestroy() {
    this.shippingSubscription.unsubscribe();
  }

  load() {
    if (this.order.buyingAccountId) {
      if (this.order.products.length === 0) {
        return;
      }

      this.isLoading = true;
      this.shippingOptions = null;

      clearTimeout(this.shippingOptionsTimer);
      this.shippingOptionsTimer = setTimeout(() => {
        this.orderService.loadShippingOptions(this.order)
          .then(shippingOptions => {
            this.shippingOptions = shippingOptions;

            if (shippingOptions) {
              this.order.flatShippingPrice = shippingOptions.flatShippingPrice;
            }

            if (shippingOptions && shippingOptions.options.length > 0) {
              this.shippingFormGroup.controls.shipping.setValue(shippingOptions.options[0]);
            }
          })
          .finally(() => this.isLoading = false);
      }, 500);
    }
  }

  get value(): Shipping {
    return this.shippingFormGroup.value.shipping;
  }

  set value(shipping: Shipping) {
    this.shippingFormGroup.controls.shipping.setValue(shipping);
  }

  get invalid() {
    return !this.value || !this.shippingOptions || this.isLoading;
  }

  get isPoBox(): boolean {
    return isPoBox(this.order.shippingAddress);
  }
}
