import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from '../users/auth.service';
import {
  Account,
  Lead,
  AccountNote,
  AccountHistory,
  AccountVolumeInterval,
  AccountVolume,
  deserializeAccount,
  deserializeAccountHistory,
  deserializeAccountNote,
  deserializeAccountVolumeInterval,
  deserializeAccountVolume
} from './account';
import {Address} from '../address';
import {Product} from '../products/product';
import {ErrorHandler} from '../error-handler.service';
import {Router} from '@angular/router';
import {deserializeOrder, Order, Payment, Subscription} from '../shopping/order';
import {Subscription as RxJsSubscription, Observable, lastValueFrom} from 'rxjs';
import {User} from '../users/user';
import {CommissionRun, deserializeCommissionRun} from '../commission';
import {Store} from '@ngrx/store';
import {SET_CURRENT_SUBSCRIPTIONS} from '../reducers';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnDestroy {
  private accountUrl = 'rest/account';
  private validateUrl = 'rest/validate';
  public currentAccount: Account;
  public retailOnly = true;

  private currentUser: User;
  private currentUserSubscription: RxJsSubscription;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private errorHandler: ErrorHandler,
    private store: Store<any>,
    private translate: TranslateService) {

    this.currentUserSubscription = auth.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      if (currentUser && currentUser.account) {
        this.loadSubscriptions(currentUser.account.id)
          .then(subscriptions => {
            this.setCurrentSubscriptions(subscriptions);
          });
      }
    });
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  async loadAccounts(
    search?: string,
    page?: number,
    includeSystem?: boolean,
    include?: number,
    exclude?: number,
    maxLevel?: number): Promise<Account[]> {

    let params = new HttpParams().set('includeSystem', includeSystem ? 'true' : 'false');

    if (page != null) {
      params = params.set('page', page.toString(10));
    }
    if (search != null) {
      params = params.set('search', search);
    }
    if (include != null) {
      params = params.set('include', include.toString(10));
    }
    if (exclude != null) {
      params = params.set('exclude', exclude.toString(10));
    }
    if (maxLevel != null) {
      params = params.set('maxLevel', maxLevel.toString(10));
    }

    const o = this.http.get<Account[]>(this.accountUrl, {params});
    const accounts = await lastValueFrom(o);
    return accounts.map(account => deserializeAccount(account, this.translate));
  }

  async loadAccountDetails(accountIdOrUrl: string | number): Promise<Account> {
    const o = this.http.get<Account>(`${this.accountUrl}/${accountIdOrUrl}`);
    return deserializeAccount(await lastValueFrom(o), this.translate);
  }

  async loadCurrentAccount(accountIdOrUrl: string): Promise<Account> {
    if (!accountIdOrUrl) {
      this.currentAccount = null;
      this.retailOnly = false;

      return new Promise((resolve) => {
        resolve(null);
      });
    }

    if (!this.currentAccount || accountIdOrUrl !== this.currentAccount.url) {
      return this.loadAccountDetails(accountIdOrUrl)
        .then(account => {
          if (!account.url || (account.rank && account.rank.index < 0)) {
            this.router.navigate([`/${accountIdOrUrl}/404`], {skipLocationChange: true});
          } else {
            this.currentAccount = account;
            this.retailOnly = account.retailOnly;
          }

          return account;
        }).catch(error => {
          if (error.status === 404) {
            this.router.navigate([`/${accountIdOrUrl}/404`], {skipLocationChange: true});
          } else {
            this.errorHandler.handle('Error.LoadingAccount', error);
          }

          return null;
        });
    } else {
      return new Promise((resolve) => {
        resolve(this.currentAccount);
      });
    }
  }

  async registerLead(lead: Lead): Promise<void> {
    const accountId = this.currentAccount ? this.currentAccount.id : 0;
    const o = this.http.post<void>(`${this.accountUrl}/${accountId}/lead`, lead);
    return lastValueFrom(o);
  }

  async updateName(accountId: number, name: string): Promise<void> {
    const o = this.http.put<void>(`${this.accountUrl}/${accountId}/name`, name);
    await lastValueFrom(o);

    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updateSubscriptionSchedule(accountId: number, subSchedule: any): Promise<void> {
    const o = this.http.put<void>(`${this.accountUrl}/${accountId}/subscriptionSchedule`, subSchedule);
    await lastValueFrom(o);

    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updateTaxExempt(accountId: number, taxExempt: boolean): Promise<void> {
    const o = this.http.put<void>(`${this.accountUrl}/${accountId}/taxExempt`, taxExempt);
    await lastValueFrom(o);

    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updatePlacement(accountId: number, placement: any): Promise<void> {
    const o = this.http.put<void>(`${this.accountUrl}/${accountId}/placement`, placement);
    await lastValueFrom(o);

    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updateBillingAddress(accountId: number, address: Address): Promise<void> {
    const o = this.http.put<void>(`${this.accountUrl}/${accountId}/billingAddress`, address);
    await lastValueFrom(o);

    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updateShippingAddress(accountId: number, address: Address): Promise<void> {
    const o = this.http.put<void>(`${this.accountUrl}/${accountId}/shippingAddress`, address);
    return lastValueFrom(o);
  }

  async updateContactInfo(accountId: number, contactInfo: any): Promise<void> {
    const o = this.http.put<void>(`${this.accountUrl}/${accountId}/contactInfo`, contactInfo);
    return lastValueFrom(o);
  }

  async updatePayout(accountId: number, payout: any): Promise<void> {
    const o = this.http.put<void>(`${this.accountUrl}/${accountId}/payout`, payout);
    return lastValueFrom(o);
  }

  async updateRegistration(accountId: number, registration: any): Promise<Account> {
    const o = this.http.put<Account>(`${this.accountUrl}/${accountId}/registration`, registration);
    const account = deserializeAccount(await lastValueFrom(o), this.translate);

    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      await this.auth.refreshCurrentUser();
    }

    return account;
  }

  async updateShares(accountId: number, shares: any) {
    const o = this.http.put(`${this.accountUrl}/${accountId}/shares`, shares);
    await lastValueFrom(o);

    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updateShopify(accountId: number, shopify: {shopifyId: number}) {
    const o = this.http.put(`${this.accountUrl}/${accountId}/shopify`, shopify);
    await lastValueFrom(o);

    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      await this.auth.refreshCurrentUser();
    }
  }

  async loadOrders(accountId: number, page?: number): Promise<Order[]> {
    let params = new HttpParams();

    if (page != null) {
      params = params.set('page', page.toString(10));
    }

    const o = this.http.get<Order[]>(`${this.accountUrl}/${accountId}/order`, {params});
    const orders = await lastValueFrom(o);
    return orders.map(order => deserializeOrder(order));
  }

  async loadSales(accountId: number, page?: number): Promise<Order[]> {
    let params = new HttpParams();

    if (page != null) {
      params = params.set('page', page.toString(10));
    }

    const o = this.http.get<Order[]>(`${this.accountUrl}/${accountId}/sale`, {params});
    const orders = await lastValueFrom(o);
    return orders.map(order => deserializeOrder(order));
  }

  async loadSaleOrders(accountId: number, page?: number): Promise<Order[]> {
    let params = new HttpParams();

    if (page != null) {
      params = params.set('page', page.toString(10));
    }

    const o = this.http.get<Order[]>(`${this.accountUrl}/${accountId}/sales`, {params});
    const orders = await lastValueFrom(o);
    return orders.map(order => deserializeOrder(order));
  }

  async validateAccountUrl(accountUrl: string, accountId?: number): Promise<{ isValid: boolean, errors: any }> {
    let params = new HttpParams().set('value', accountUrl);

    if (accountId) {
      params = params.set('accountId', accountId.toString(10));
    }

    const o = this.http.get<{ isValid: boolean, errors: any }>(this.validateUrl + '/accountUrl', {params});
    return lastValueFrom(o);
  }

  async loadSavedPayments(accountId: number): Promise<Payment[]> {
    const o = this.http.get<Payment[]>(this.accountUrl + '/' + accountId + '/payment');
    return lastValueFrom(o);
  }

  async savePayment(accountId: number, payment: Payment): Promise<Payment[]> {
    const o = this.http.post<Payment[]>(`${this.accountUrl}/${accountId}/payment`, payment);
    return lastValueFrom(o);
  }

  async deleteSavedPayment(accountId: number, paymentProfileId: string): Promise<Payment[]> {
    const o = this.http.delete<Payment[]>(`${this.accountUrl}/${accountId}/payment/${paymentProfileId}`);
    return lastValueFrom(o);
  }

  async subscribe(subscription: Subscription): Promise<Subscription[]> {
    const o = this.http.post<Subscription[]>(`${this.accountUrl}/${subscription.accountId}/subscription`, subscription);
    const subscriptions = await lastValueFrom(o);

    if (this.currentUser.account && this.currentUser.account.id === subscription.accountId) {
      this.setCurrentSubscriptions(subscriptions);
    }

    return subscriptions;
  }

  async loadSubscriptions(accountId: number): Promise<Subscription[]> {
    const o = this.http.get<Subscription[]>(`${this.accountUrl}/${accountId}/subscription`);
    return lastValueFrom(o);
  }

  async loadSubscription(accountId: number, productId: number): Promise<Subscription> {
    const o = this.http.get<Subscription>(`${this.accountUrl}/${accountId}/subscription/${productId}`);
    return lastValueFrom(o);
  }

  async deleteSubscription(accountId: number, productId: number): Promise<Subscription[]> {
    const o = this.http.delete<Subscription[]>(`${this.accountUrl}/${accountId}/subscription/${productId}`);
    const subscriptions = await lastValueFrom(o);

    if (this.currentUser.account && this.currentUser.account.id === accountId) {
      this.setCurrentSubscriptions(subscriptions);
    }

    return subscriptions;
  }

  async createSubscriptionOrder(accountId: number, payment: Payment): Promise<number> {
    const o = this.http.post(`${this.accountUrl}/${accountId}/createSubscriptionOrder`, payment);
    return +await lastValueFrom(o);
  }

  async skipNextSubscriptionOrder(accountId: number): Promise<Date> {
    const o = this.http.post(`${this.accountUrl}/${accountId}/skipNextSubscriptionOrder`, null, {
      responseType: 'text'
    });

    const nextDate = moment(await lastValueFrom(o)).toDate();
    if (this.currentUser && this.currentUser.account && accountId === this.currentUser.account.id) {
      this.auth.refreshCurrentUser();
    }

    return nextDate;
  }

  async setPreferredPayment(accountId: number, paymentProfileId: string): Promise<Payment[]> {
    const o = this.http.put<Payment[]>(`${this.accountUrl}/${accountId}/preferredPayment/` + paymentProfileId, null);
    return lastValueFrom(o);
  }

  async loadCommissionRuns(accountId: number, page?: number): Promise<CommissionRun[]> {
    let params = new HttpParams();

    if (page != null) {
      params = params.set('page', page.toString(10));
    }

    const o = this.http.get<CommissionRun[]>(`${this.accountUrl}/${accountId}/commissionRun`, {params});
    const runs = await lastValueFrom(o);
    return runs.map(commission => deserializeCommissionRun(commission));
  }

  async loadChildren(accountId: number, types?: string[]): Promise<Account[]> {
    let params = new HttpParams();

    if (types) {
      types.forEach(type => params = params.set('types', type));
    }

    const o = this.http.get<Account[]>(`${this.accountUrl}/${accountId}/children`, {params});
    const children = await lastValueFrom(o);
    return children.map(child => deserializeAccount(child, this.translate));
  }

  async loadUplines(accountId: number): Promise<Account[]> {
    const o = this.http.get<Account[]>(`${this.accountUrl}/${accountId}/uplines`);
    const uplines = await lastValueFrom(o);
    return uplines.map(account => deserializeAccount(account, this.translate));
  }

  async archiveAccount(accountId: number, parentId: number): Promise<Account> {
    const params = new HttpParams().set('parentId', String(parentId));

    const o = this.http.delete<Account>(this.accountUrl + '/' + accountId, {params});
    return deserializeAccount(await lastValueFrom(o), this.translate);
  }

  async moveAccount(accountId: number, parentId: number): Promise<Account> {
    const o = this.http.put<Account>(`${this.accountUrl}/${accountId}/move`, parentId);
    return deserializeAccount(await lastValueFrom(o), this.translate);
  }

  async loadNotes(accountId: number): Promise<AccountNote[]> {
    const o = this.http.get<AccountNote[]>(`${this.accountUrl}/${accountId}/note`);
    const notes = await lastValueFrom(o);
    return notes.map(note => deserializeAccountNote(note));
  }

  async addNote(accountId: number, note: AccountNote): Promise<AccountNote[]> {
    const o = this.http.post<AccountNote[]>(`${this.accountUrl}/${accountId}/note`, note);
    const notes = await lastValueFrom(o);
    return notes.map(n => deserializeAccountNote(n));
  }

  async deleteNote(accountId: number, noteId: number): Promise<AccountNote[]> {
    const o = this.http.delete<AccountNote[]>(`${this.accountUrl}/${accountId}/note/${noteId}`);
    const notes = await lastValueFrom(o);
    return notes.map(note => deserializeAccountNote(note));
  }

  async loadAccountHistory(accountId: number): Promise<AccountHistory[]> {
    const o = this.http.get<AccountHistory[]>(`${this.accountUrl}/${accountId}/history`);
    const history = await lastValueFrom(o);
    return history.map(item => deserializeAccountHistory(item, this.translate));
  }

  get currentSubscriptions(): Observable<Subscription[]> {
    return this.store.select('currentSubscriptions');
  }

  setCurrentSubscriptions(subscriptions: Subscription[]) {
    this.store.dispatch({
      type: SET_CURRENT_SUBSCRIPTIONS,
      payload: subscriptions
    });
  }

  async loadProducts(accountId: number): Promise<Product[]> {
    const o = this.http.get<Product[]>(`${this.accountUrl}/${accountId}/product`);
    return lastValueFrom(o);
  }

  async assignProduct(accountId: number, productId: number): Promise<Product[]> {
    const o = this.http.post<Product[]>(`${this.accountUrl}/${accountId}/product/${productId}`, null);
    return lastValueFrom(o);
  }

  async removeProduct(accountId: number, productId: number): Promise<Product[]> {
    const o = this.http.delete<Product[]>(`${this.accountUrl}/${accountId}/product/${productId}`);
    return lastValueFrom(o);
  }

  async loadVolumeIntervals(accountId: number): Promise<AccountVolumeInterval[]> {
    const o = this.http.get<AccountVolumeInterval[]>(`${this.accountUrl}/${accountId}/volume`);
    const intervals = await lastValueFrom(o);
    return intervals.map(interval => deserializeAccountVolumeInterval(interval));
  }

  async loadVolume(accountId: number, start: Date): Promise<AccountVolume> {
    const o = this.http.get<AccountVolume>(`${this.accountUrl}/${accountId}/volume/${start.toISOString()}`);
    return deserializeAccountVolume(await lastValueFrom(o));
  }

  async applyReseller(wholesaleApplication): Promise<void> {
    const o = this.http.post<void>(`${this.accountUrl}/0/resellerApplication`, wholesaleApplication);
    return lastValueFrom(o);
  }
}
