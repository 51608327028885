import {Component, Input, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ErrorHandler} from '../error-handler.service';
import {AmbassadorRegistrationFormComponent} from './ambassador-registration-form.component';
import {Account, AccountType} from '@vni/common/accounts/account';
import {AccountService} from '@vni/common/accounts/account.service';
import {TermsAndConditionsDialogComponent} from '../terms-and-conditions/terms-and-conditions-dialog.component';

@Component({
  templateUrl: './ambassador-registration-dialog.component.html',
  styleUrls: ['./ambassador-registration-dialog.component.scss']
})
export class AmbassadorRegistrationDialogComponent {
  @Input() account: Account;

  submitting = false;

  public ambassadorRegistration: AmbassadorRegistrationFormComponent;

  @ViewChild('ambassadorRegistration', {static: false}) set setAmbassadorRegistration(theElementRef: AmbassadorRegistrationFormComponent) {
    this.ambassadorRegistration = theElementRef;
  }

  constructor(
    public dialogRef: MatDialogRef<AmbassadorRegistrationDialogComponent>,
    private dialog: MatDialog,
    private errorHandler: ErrorHandler,
    private accountService: AccountService) {
  }

  get invalid() {
    return !this.ambassadorRegistration || this.ambassadorRegistration.invalid;
  }

  showAcceptTerms() {
    const dialogRef = this.dialog.open(TermsAndConditionsDialogComponent);
    dialogRef.componentInstance.accountType = AccountType.AMBASSADOR;
    const subscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.becomePartner();
      }
      subscription.unsubscribe();
    });
  }

  becomePartner() {
    this.submitting = true;

    const registration = this.ambassadorRegistration.value;
    registration.type = 'AMBASSADOR';
    this.accountService.updateRegistration(this.account.id, registration)
      .then(() => this.dialogRef.close())
      .catch(error => {
        this.submitting = false;

        if (error.status === 400) {
          this.errorHandler.handle('Error.ProcessingPayment', error);
        } else {
          this.errorHandler.handle('Error.BecomingPartner', error);
        }
      });
  }
}
