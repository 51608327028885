import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslateService, TranslateCompiler} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';

import {StoreModule, ActionReducerMap} from '@ngrx/store';
import {CurrencyPipe} from '@angular/common';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

import {
  FpLocaleModule,
  FpYouTubeModule,
  FpWindowModule,

  Locale
} from '@fp/common';

import {
  Config,
  AuthInterceptor,
  VniUserModule,

  Cart,
  Error,
  User,
  Subscription,

  currentUserReducer,
  currentSubscriptionsReducer,
  configReducer,
  cartReducer,
  errorReducer,
  countryCodeReducer,

  VniShoppingModule
} from '@vni/common';

import {VniErrorPageModule} from './error-page/error-page.module';
import {VniComponent} from './vni.component';

import {ROUTES} from './routes';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export interface State {
  error: Error;
  currentUser: User;
  currentSubscriptions: Subscription[];
  config: Config;
  cart: Cart;
  countryCode: string;
}

export const reducers: ActionReducerMap<State> = {
  error: errorReducer,
  currentUser: currentUserReducer,
  currentSubscriptions: currentSubscriptionsReducer,
  config: configReducer,
  cart: cartReducer,
  countryCode: countryCodeReducer
};

@NgModule({
  declarations: [
    VniComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false
      }
    }),
    MatSnackBarModule,
    MatDialogModule,
    MatBottomSheetModule,
    FpLocaleModule,
    FpYouTubeModule,
    FpWindowModule,
    VniErrorPageModule,
    VniUserModule,
    VniShoppingModule
  ],
  providers: [
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [VniComponent]
})
export class VniModule {
  constructor(
    translate: TranslateService,
    locale: Locale) {

    translate.setDefaultLang('en');
    translate.use(locale.messageCode);
  }
}

