import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from './auth.service';
import {ErrorHandler} from '../error-handler.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  templateUrl: './password-reset-request-dialog.component.html'
})
export class PasswordResetRequestDialogComponent {
  private _inProgress = false;

  passwordResetRequestForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PasswordResetRequestDialogComponent>,
    private auth: AuthService,
    private errorHandler: ErrorHandler,
    private translate: TranslateService,
    private snackBar: MatSnackBar) {

    this.passwordResetRequestForm = new FormGroup({
      userName: new FormControl(null, Validators.required)
    });
  }

  get inProgress() {
    return this._inProgress;
  }

  requestPasswordReset() {
    this._inProgress = true;

    this.auth.requestPasswordReset(this.passwordResetRequestForm.value.userName)
      .then(() => {
        this._inProgress = false;
        this.dialogRef.close();

        this.snackBar.open(this.translate.instant('PasswordResetSentMessage'), this.translate.instant('DISMISS'), {
          duration: 10000,
        });
      }).catch(error => {
      this._inProgress = false;

      if (error.status === 404) {
        this.errorHandler.handle('Error.UnableToFindAccount', error);
      } else {
        this.errorHandler.handle('Error.UnableToResetPassword', error);
      }
    });
  }
}
