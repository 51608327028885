import {Component, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {Order} from '../shopping/order';
import {User} from '../users/user';
import {Account} from '@vni/common/accounts/account';
import {AuthService} from '../users/auth.service';
import {Subscription} from 'rxjs';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {AmbassadorRegistrationDialogComponent} from '../users/ambassador-registration-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'vni-checkout-account',
  templateUrl: './checkout-account.component.html',
  styleUrls: ['./checkout-account.component.scss']
})
export class CheckoutAccountComponent implements OnDestroy {
  @Input() order: Order;
  @Output() onChange = new EventEmitter();

  accountPickerFormGroup: FormGroup;

  currentUser: User;
  currentUserSubscription: Subscription;
  accountSubscription: Subscription;

  constructor(
    public auth: AuthService,
    private dialog: MatDialog) {

    this.accountPickerFormGroup = new FormGroup({
      account: new FormControl(null, Validators.required),
    });

    this.currentUserSubscription = this.auth.currentUser
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.accountPickerFormGroup.controls.account.setValue(null);
        setTimeout(() => this.onChange.emit(this.value));
      });

    this.accountSubscription = this.accountPickerFormGroup.controls.account.valueChanges.subscribe(account =>
      setTimeout(() => this.onChange.emit(this.value)));
  }

  get value(): Account {
    if (this.currentUser) {
      return this.currentUser.isAdmin ? this.accountPickerFormGroup.value.account : this.currentUser.account;
    }

    return null;
  }

  get invalid() {
    return !this.value;
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
    this.accountSubscription.unsubscribe();
  }

  becomeAmbassador() {
    const dialogRef = this.dialog.open(AmbassadorRegistrationDialogComponent, {panelClass: 'dialog-600px', disableClose: true});
    dialogRef.componentInstance.account = this.value;
  }
}
