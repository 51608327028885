import {Component, Input, OnDestroy, OnChanges} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ErrorHandler} from '../error-handler.service';
import {
  Country, GeoService
} from '@fp/common';
import {Subscription} from 'rxjs';
import {Address} from '../address';

@Component({
  selector: 'vni-checkout-address',
  templateUrl: './checkout-address.component.html'
})
export class CheckoutAddressComponent implements OnChanges, OnDestroy {
  @Input() shippingAddress: Address;

  shippingAddrFormGroup: FormGroup;

  countries: Map<String, Country>;

  shippingSelectedCountry: Country;
  shippingCountrySubscription: Subscription;

  constructor(
    private errorHandler: ErrorHandler,
    private geoService: GeoService) {

    this.shippingAddrFormGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      street: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      regionIsoCode: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      countryIsoCode: new FormControl(null, Validators.required)
    });

    this.geoService.getCountries().then(countries => {
      this.countries = countries;

      this.update();
    });

    if (this.shippingCountrySubscription) {
      this.shippingCountrySubscription.unsubscribe();
    }

    this.shippingCountrySubscription = this.shippingAddrFormGroup.controls.countryIsoCode.valueChanges.subscribe(countryIsoCode => {
      if (!this.countries || (this.shippingSelectedCountry && this.shippingSelectedCountry.isoCode === countryIsoCode)) {
        return;
      }

      this.shippingSelectedCountry = this.countries[countryIsoCode];
      this.shippingAddrFormGroup.controls.regionIsoCode.setValue(null);

      if (this.shippingSelectedCountry) {
        this.geoService.loadRegions(this.shippingSelectedCountry)
          .then(regions => {
            regions.length > 0 ?
              this.shippingAddrFormGroup.controls.regionIsoCode.enable() :
              this.shippingAddrFormGroup.controls.regionIsoCode.disable();
          });
      } else {
        this.shippingAddrFormGroup.controls.regionIsoCode.disable();
      }

      if (!this.shippingSelectedCountry
        || !this.shippingSelectedCountry.postalCodeType
        || this.shippingSelectedCountry.postalCodeType === 'NULL') {
        this.shippingAddrFormGroup.controls.postalCode.disable();
      }
    });
  }

  ngOnChanges() {
    this.update();
  }

  private update() {
    if (this.shippingAddress) {
      this.shippingAddrFormGroup.controls.name.setValue(this.shippingAddress.name);
      this.shippingAddrFormGroup.controls.street.setValue(this.shippingAddress.street);
      this.shippingAddrFormGroup.controls.city.setValue(this.shippingAddress.city);
      this.shippingAddrFormGroup.controls.postalCode.setValue(this.shippingAddress.postalCode);
      this.shippingAddrFormGroup.controls.countryIsoCode.setValue(this.shippingAddress.country.isoCode);
      this.shippingAddrFormGroup.controls.regionIsoCode.setValue(this.shippingAddress.region.isoCode);
    }
  }

  get value(): Address {
    const address = Object.assign({}, this.shippingAddrFormGroup.value);
    address.country = this.shippingSelectedCountry;
    if (address.country) {
      address.region = address.country.regions.find(region => region.isoCode === this.shippingAddrFormGroup.value.regionIsoCode);
    }

    return address;
  }

  get invalid() {
    return this.shippingAddrFormGroup.invalid;
  }

  ngOnDestroy() {
    this.shippingCountrySubscription.unsubscribe();
  }
}
