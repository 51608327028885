import {Component, OnDestroy, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {AuthService} from '../users/auth.service';
import {Router} from '@angular/router';


@Component({
  templateUrl: './login-dialog.component.html'
})
export class LoginDialogComponent implements OnDestroy {
  @Input() retail = true;

  currentUserSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private auth: AuthService,
    public router: Router,
    public location: Location) {

    this.currentUserSubscription = this.auth.currentUser
      .subscribe(currentUser => {
        if (currentUser) {
          dialogRef.close(currentUser);
        }
      });
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }
}
