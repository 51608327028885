import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './subscription-rewards-dialog.component.html',
  styleUrls: ['./subscription-rewards-dialog.component.scss']
})
export class SubscriptionRewardsDialogComponent {
  @Input() retail = false;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionRewardsDialogComponent>) {
  }
}
