import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription as ProductSubscription } from '../shopping/order';
import { AccountService } from '@vni/common/accounts/account.service';
import { ProductService } from '../products/product.service';
import { Account } from '@vni/common/accounts/account';
import { ErrorHandler } from '../error-handler.service';
import { PaymentComponent } from '../payment/payment.component';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PricingType } from '../products/product';

@Component({
  templateUrl: './product-subscription-dialog.component.html',
  styleUrls: ['./product-subscription-dialog.component.scss']
})
export class ProductSubscriptionDialogComponent implements OnInit, OnDestroy {
  @Input() retail = false;
  @Input() productId: number;
  @Input() accountId: number;

  account: Account;

  isLoading = false;
  submitting = false;

  productPickerFormGroup: FormGroup;
  productSubscription: Subscription;

  existingSubscription: ProductSubscription;

  public payment: PaymentComponent;
  @ViewChild('payment', { static: false }) set setPayment(theElementRef: PaymentComponent) {
    this.payment = theElementRef;
  }

  constructor(
    private accountService: AccountService,
    private productService: ProductService,
    private errorHandler: ErrorHandler,
    public dialogRef: MatDialogRef<ProductSubscriptionDialogComponent>) {

    this.productPickerFormGroup = new FormGroup({
      product: new FormControl(null, Validators.required),
      quantity: new FormControl(1, Validators.required)
    });

    this.productSubscription = this.productPickerFormGroup.controls.product.valueChanges.subscribe(product => {
      const newProductId = product ? product.id : null;
      this.productId = newProductId;

      if (newProductId && (!this.existingSubscription || this.existingSubscription.productId !== newProductId)) {
        this.loadExistingSubscription();
      }
    });
  }

  ngOnInit() {
    if (this.productId) {
      this.loadExistingSubscription();
    }

    this.accountService.loadAccountDetails(this.accountId)
      .then(account => this.account = account);

    if (this.productId) {
      const pricingType = this.retail ? PricingType.RETAIL : PricingType.WHOLESALE;
      this.productService.loadProductDetails(this.productId, pricingType)
        .then(product => this.productPickerFormGroup.controls.product.setValue(product));
    }
  }

  loadExistingSubscription() {
    this.isLoading = true;

    this.accountService.loadSubscription(this.accountId, this.productId)
      .then(subscription => {
        this.existingSubscription = subscription;
        this.productPickerFormGroup.controls.quantity.setValue(subscription.quantity);
        this.isLoading = false;
      }).catch(error => {
        this.productPickerFormGroup.controls.quantity.setValue(1);
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.productSubscription.unsubscribe();
  }

  subscribe() {
    this.submitting = true;

    this.accountService.subscribe(this.newSubscription)
      .then(subscriptions => {
        this.submitting = false;
        this.dialogRef.close(subscriptions);
      }).catch(error => {
        this.submitting = false;
        this.errorHandler.handle('Error.AddingSubscription', error);
      });
  }

  get newSubscription(): ProductSubscription {
    const newSubscription = {
      accountId: this.accountId,
      productId: this.productPickerFormGroup.value.product.id,
      quantity: this.productPickerFormGroup.value.quantity
    } as ProductSubscription;

    if (this.payment) {
      newSubscription.payment = this.payment.value;
    }

    return newSubscription;
  }

  get invalid() {
    return !this.accountId
      || !this.productPickerFormGroup.value.product
      || !Number.isInteger(this.productPickerFormGroup.value.quantity)
      || this.productPickerFormGroup.value.quantity < 1;
  }
}
