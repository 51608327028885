import {Component, Input, Output, EventEmitter} from '@angular/core';
import {MASKS} from '@fp/common';
import {DISCOUNT_CODES, Order} from './order';

@Component({
  selector: 'vni-checkout-price-editor',
  templateUrl: './checkout-price-editor.component.html',
  styleUrls: ['./checkout-price-editor.component.scss']
})
export class CheckoutPriceEditorComponent {
  @Input() order: Order;
  @Output() onChange = new EventEmitter();

  editOrder: Order;
  protected readonly DISCOUNT_CODES = DISCOUNT_CODES;
  protected readonly MASKS = MASKS;
}
