import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {ErrorHandler} from '../error-handler.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Product, PricingType, ProductFilter} from './product';
import {ProductService} from './product.service';

@Component({
  selector: 'vni-product-picker-modal',
  templateUrl: './product-picker-modal.component.html',
  styleUrls: ['./product-picker-modal.component.scss']
})
export class ProductPickerModalComponent implements OnInit, OnDestroy {
  @Input() filter: ProductFilter;
  @Input() accountId: number;
  @Input() includeInactive: boolean;

  selectedProduct: Product;

  productSearch: FormGroup;
  submitted = false;
  products: Product[];

  inProgress = false;
  timer: any;
  page = -1;
  hasMorePages = false;

  searchSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ProductPickerModalComponent>,
    private productService: ProductService,
    private errorHandler: ErrorHandler) {
  }

  ngOnInit() {
    this.productSearch = new FormGroup({
      search: new FormControl(null)
    });

    this.searchSubscription = this.productSearch.valueChanges.subscribe(data => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.loadFirstPage(), 1000);
    });

    this.loadFirstPage();
  }

  loadFirstPage() {
    this.page = -1;
    this.loadNextPage();
  }

  loadNextPage() {
    this.inProgress = true;
    this.page++;

    this.productService.loadProducts(
      PricingType.RETAIL,
      this.productSearch.value.search,
      this.page,
      this.includeInactive,
      this.filter,
      this.accountId).then(products => {
      if (this.page === 0) {
        this.products = products;
      } else {
        this.products = this.products.concat(products);
      }

      this.hasMorePages = products.length > 0;
      this.inProgress = false;
    }).catch((error) => {
      this.inProgress = false;
      this.errorHandler.handle('Error.LoadingProducts', error);
    });
  }

  onScroll(event) {
    const target = event.target;
    const scrollHeight = target.scrollHeight;
    const scrollTop = target.scrollTop;
    const height = target.offsetHeight;

    if (scrollTop + height + 20 > scrollHeight && !this.inProgress && this.hasMorePages) {
      this.loadNextPage();
    }
  }

  select(product: Product) {
    this.dialogRef.close(product);
  }

  isSelected(product: Product): boolean {
    return this.selectedProduct && this.selectedProduct.id === product.id;
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }
}
