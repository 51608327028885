import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';

import {MatChipsModule} from '@angular/material/chips';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {TagService} from './tag.service';
import {TagPickerComponent} from './tag-picker.component';

@NgModule({
  declarations: [
    TagPickerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,

    FlexLayoutModule,
    MatChipsModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatInputModule
  ],
  exports: [
    TagPickerComponent
  ],
  providers: [
    TagService
  ]
})
export class VniTagModule {
}
