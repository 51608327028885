import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from '../users/auth.service';
import {Article} from './news';
import {Observable, Observer} from 'rxjs';
import {Tag} from '../tags/tag';

@Injectable()
export class NewsService {
  private articleUrl = 'rest/article';

  constructor(
    private http: HttpClient,
    private auth: AuthService) {
  }

  async loadArticles(
    search?: string,
    page?: number,
    edit?: boolean,
    filter?: any): Promise<Article[]> {

    let params = new HttpParams()
      .set('edit', String(edit));

    if (page != null) {
      params = params.set('page', page.toString(10));
    }
    if (search != null) {
      params = params.set('search', search);
    }
    if (filter != null) {
      params = params.set('filter', JSON.stringify(filter));
    }

    return this.http.get(this.articleUrl, {params}).toPromise()
      .then(result => result as Article[])
      .then(news => {
        news.forEach(article => Article.deserialize(article));
        return news;
      });
  }

  async loadArticle(articleId: number): Promise<Article> {
    return this.http.get(this.articleUrl + '/' + articleId).toPromise()
      .then(result => result as Article)
      .then(article => {
        Article.deserialize(article);
        return article;
      });
  }

  async addArticle(article: Article): Promise<number> {
    return this.http.post(this.articleUrl, article)
      .toPromise()
      .then(response => +response as number);
  }

  async updateEnabled(articleId: number, isEnabled: boolean) {
    return this.http.put(this.articleUrl + '/' + articleId + '/enabled', String(isEnabled))
      .toPromise();
  }

  async updateValue(productId: number, field: string, content: string): Promise<Object> {
    return this.http.put(this.articleUrl + '/' + productId + '/value/' + field, content)
      .toPromise();
  }

  uploadImgFile(articleId: number, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);

    return new Observable((observer: Observer<any>) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);

        observer.next(progress);
      };

      xhr.open('POST', this.articleUrl + '/' + articleId + '/imgFile', true);

      const headers = this.auth.headers();
      headers.keys().forEach(key => xhr.setRequestHeader(key, headers.get(key)));

      xhr.send(formData);
    });
  }

  async deleteImgFile(articleId: number) {
    return this.http.delete(this.articleUrl + '/' + articleId + '/imgFile').toPromise();
  }

  async archiveArticle(articleId: number) {
    return this.http.delete(this.articleUrl + '/' + articleId).toPromise();
  }

  async loadTags(articleId: number): Promise<Tag[]> {
    return this.http.get(this.articleUrl + '/' + articleId + '/tag').toPromise()
      .then(response => response as Tag[]);
  }

  async updateTags(articleId: number, tags: Tag[]) {
    return this.http.post(this.articleUrl + '/' + articleId + '/tag', tags)
      .toPromise();
  }
}
