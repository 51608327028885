import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';

import {ProductPickerModalComponent} from './product-picker-modal.component';
import {ProductPickerComponent} from './product-picker.component';

import {NguCarouselModule} from '@ngu/carousel';
import {ProductCarouselComponent} from './product-carousel.component';
import {VniTagModule} from '../tags/tag.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        TranslateModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatListModule
    ],
    declarations: [
        ProductPickerModalComponent,
        ProductPickerComponent
    ],
    exports: [
        ProductPickerComponent,
        ProductPickerModalComponent
    ]
})
export class VniProductModule {
}

@NgModule({
    declarations: [
        ProductCarouselComponent
    ],
    exports: [
        ProductCarouselComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,

        NguCarouselModule,
        VniTagModule
    ]
})
export class VniProductCarouselModule {
}
