import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormGroup, FormControl, Validators} from '@angular/forms';

import {
  ErrorHandler,
  AccountService,
  Region,
  PREFERRED_CONTACT_TIMES
} from '@vni/common';
import {GeoService} from '@fp/common';
import {DOCUMENT} from '@angular/common';

@Component({
  templateUrl: './reseller-application-dialog.component.html',
  styleUrls: ['./reseller-application-dialog.component.scss']
})
export class ResellerApplicationDialogComponent implements OnInit {
  contactInfoForm: FormGroup;
  aboutBusinessForm: FormGroup;

  submitting = false;
  submitted = false;
  private showStepTimeout: any;

  states: Region[];

  constructor(
    public dialogRef: MatDialogRef<ResellerApplicationDialogComponent>,
    private errorHandler: ErrorHandler,
    private accountService: AccountService,
    private geoService: GeoService,
    @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit() {
    this.contactInfoForm = new FormGroup({
      contactName: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      preferredContactTime: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      regionIsoCode: new FormControl(null, Validators.required),
    });

    this.aboutBusinessForm = new FormGroup({
      businessName: new FormControl(null, Validators.required),
      webAddress: new FormControl(null),
      aboutBusiness: new FormControl(null, Validators.required),
      howYouHeard: new FormControl(null)
    });

    setTimeout(() => this.loadStates());
  }

  private async loadStates() {
    const countries = await this.geoService.getCountries();
    this.states = await this.geoService.loadRegions(countries['US']);
  }

  async apply() {
    this.submitting = true;
    this.submitted = false;

    const value = {...this.contactInfoForm.value, ...this.aboutBusinessForm.value};

    try {
      await this.accountService.applyReseller(value);
      this.submitting = false;
      this.submitted = true;
    } catch (error) {
      this.submitting = false;
      this.submitted = false;
      await this.errorHandler.handle('Error.ApplyingForWholesaleProgram', error);
    }
  }

  stepChanged(e) {
    clearTimeout(this.showStepTimeout);
    this.showStepTimeout = setTimeout(() => {
      this.document.getElementById('reg-step-' + e.selectedIndex)
        .parentElement.parentElement.scrollIntoView({
        behavior: 'smooth', block: 'start', inline:
          'nearest'
      });
    }, 500);
  }

  get invalid() {
    return this.contactInfoForm.invalid
      || this.aboutBusinessForm.invalid;
  }

  get preferredContactTimes(): String[] {
    return PREFERRED_CONTACT_TIMES;
  }
}
