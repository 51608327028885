import { Component, Input, OnInit, OnChanges, OnDestroy, HostListener } from '@angular/core';
import { ProductService } from './product.service';
import { Product, PricingType } from './product';
import { Tag } from '../tags/tag';
import { UrlService } from '../url.service';
import { ErrorHandler } from '../error-handler.service';
import { User } from '../users/user';
import { OrderService } from '../shopping/order.service';
import { AuthService } from '../users/auth.service';
import { Subscription } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'vni-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss']
})
export class ProductCarouselComponent implements OnInit, OnChanges, OnDestroy {
  @Input() retail = false;
  @Input() search: string;
  @Input() tags: Tag[];
  @Input() keys: string[];

  carouselHeight = '480px';
  carouselBanner: any;

  products: Product[];
  page = -1;
  hasMorePages = false;
  timer: any;

  loadingNextPage = false;

  currentUser: User;
  currentUserSubscription: Subscription;
  countryCodeSubscription: Subscription;
  orderPlacedSubscription: Subscription;

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.calcHeight();
  }

  constructor(
    private productService: ProductService,
    private errorHandler: ErrorHandler,
    public url: UrlService,
    public order: OrderService,
    private media: MediaObserver,
    auth: AuthService) {

    this.currentUserSubscription = auth.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.loadFirstPage();
    });

    this.countryCodeSubscription = auth.countryCode.subscribe(countryCode => this.loadFirstPage());

    this.orderPlacedSubscription = this.order.orderPlaced.subscribe(orderId => {
      if (orderId) {
        this.loadFirstPage();
      }
    });
  }

  ngOnInit() {
    this.carouselBanner = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: {
        timing: 5000,
        initialDelay: 1000
      },
      point: {
        visible: true
      },
      load: 2,
      loop: true,
      touch: true
    };

    this.calcHeight();
  }

  ngOnChanges() {
    this.loadFirstPage();
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
    this.countryCodeSubscription.unsubscribe();
    this.orderPlacedSubscription.unsubscribe();
  }

  loadFirstPage() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.page = -1;
      return this.loadNextPage();
    }, 280);
  }

  loadNextPage() {
    if (!this.keys && !this.tags) {
      this.products = null;
      return;
    }

    this.loadingNextPage = true;
    this.page++;

    const pricingType = this.retail ? PricingType.RETAIL : PricingType.WHOLESALE;

    let tags: Array<Tag[] & any>;

    if (this.tags && this.tags.length > 0) {
      tags = this.tags.slice();
    } else if (this.keys) {
      tags = new Array<Tag[] & any>();
      this.keys.forEach(key => tags.push({ key: key }));
    } else {
      tags = [{ key: 'PAK' }];
    }

    return this.productService.loadProducts(pricingType, this.search, this.page, true, { tags: tags })
      .then(products => {
        if (this.page === 0) {
          this.products = products;
        } else {
          this.products = this.products.concat(products);
        }

        this.hasMorePages = products.length > 0;
        this.loadingNextPage = false;
      }).catch(error => {
        this.loadingNextPage = false;
        this.errorHandler.handle('Error.LoadingProducts', error);
      });
  }

  calcHeight() {
    this.carouselHeight = this.media.isActive('gt-sm') ?
      '480px' : window.innerHeight + 'px';
  }
}
