import {Injectable, OnDestroy} from '@angular/core';
import {User} from './user';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Address} from '../address';
import {lastValueFrom, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {UrlService} from '../url.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private userUrl = 'rest/user';
  private validateUrl = 'rest/validate';

  private currentUser: User;
  private currentUserSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private url: UrlService,
    private translate: TranslateService) {

    this.currentUserSubscription = auth.currentUser.subscribe(currentUser => this.currentUser = currentUser);
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  async register(newReg: any): Promise<User> {
    const o = this.http.post<User>(`${this.userUrl}/register`, newReg);
    const newUser = await lastValueFrom(o);

    if (!this.currentUser) {
      User.deserialize(newUser, this.translate);
      this.auth.setCurrentUser(newUser);

      const url = this.url.build(['/', 'shop']);
      await this.router.navigate(url);
    } else if (this.currentUser && this.currentUser.isAdmin) {
      await this.router.navigate(['/backoffice/account', newUser.account.id]);
    }

    return newUser;
  }

  async addUser(newUser: any): Promise<User> {
    const o = this.http.post<User>(`${this.userUrl}/addUser`, newUser);
    const user = await lastValueFrom(o);

    await this.router.navigate(['/backoffice/user', user.userName]);
    return user;
  }

  async loadUsers(): Promise<User[]> {
    const o = this.http.get<User[]>(this.userUrl);
    return lastValueFrom(o);
  }

  async loadUserDetails(userName: string): Promise<User> {
    const o = this.http.get<User>(`${this.userUrl}/${userName}`);
    return User.deserialize(await lastValueFrom(o), this.translate);
  }

  async updateName(userName: string, name: any): Promise<void> {
    const o = this.http.put<void>(`${this.userUrl}/${userName}/name`, name);
    await lastValueFrom(o);

    if (userName === this.currentUser.userName) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updatePassword(userName: string, password: any): Promise<void> {
    const o = this.http.put<void>(`${this.userUrl}/${userName}/password`, password);
    return lastValueFrom(o);
  }

  async updateRoles(userName: string, roles: string[]): Promise<void> {
    const o = this.http.put<void>(`${this.userUrl}/${userName}/role`, roles);
    await lastValueFrom(o);

    if (userName === this.currentUser.userName) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updateAddress(userName: string, address: Address): Promise<void> {
    const o = this.http.put<void>(`${this.userUrl}/${userName}/address`, address);
    await lastValueFrom(o);

    if (userName === this.currentUser.userName) {
      await this.auth.refreshCurrentUser();
    }
  }

  async updateContactInfo(userName: string, contactInfo: any): Promise<void> {
    const o = this.http.put<void>(`${this.userUrl}/${userName}/contactInfo`, contactInfo);
    await lastValueFrom(o);

    if (userName === this.currentUser.userName) {
      await this.auth.refreshCurrentUser();
    }
  }

  async validateUserName(userName: string): Promise<any> {
    const params = new HttpParams().set('value', userName);

    const o = this.http.get(this.validateUrl + '/userName', {params});
    return lastValueFrom(o);
  }

  async sendInvite(invite: any): Promise<void> {
    const o = this.http.post<void>(`${this.userUrl}/sendInvite`, invite);
    return lastValueFrom(o);
  }
}
