import {Inject, Injectable} from '@angular/core';
import {AccountService} from '@vni/common/accounts/account.service';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  site = 'new';
  shopRootUrl = 'https://shop.vni.life';
  affiliateRootUrl = 'https://affiliate.vni.life';
  businessRootUrl = 'http://business.vni.life';

  constructor(public accountService: AccountService,
              @Inject(DOCUMENT) private document: Document) {
  }

  get currentAccountUrl() {
    return this.accountService.currentAccount
      ? this.accountService.currentAccount.url || this.accountService.currentAccount.id
      : 'corporate';
  }

  build(parts: (string | number)[], switchSites: boolean = false) {
    const url = [...parts];

    if (!switchSites) {
      url.splice(1, 0, this.site);
    }

    url.splice(switchSites ? 1 : 2, 0, this.currentAccountUrl);

    return url;
  }

  get shopUrl() {
    return `${this.shopRootUrl}/collections/all` +
      (this.accountService.currentAccount?.id ? '?ref=vni.life/' + this.accountService.currentAccount.id : '');
  }

  get affiliateRegistrationUrl() {
    return `${this.affiliateRootUrl}/create-account` +
      (this.accountService.currentAccount?.id ? '?ref=vni.life/' + this.accountService.currentAccount.id : '');
  }

  goTo(url: string) {
    this.document.location = url;
  }
}
