import {Component, Output, EventEmitter, OnInit, OnDestroy, Input} from '@angular/core';
import {UrlService} from '../url.service';
import {User} from '../users/user';
import {AuthService} from '../users/auth.service';
import {OrderService} from '../shopping/order.service';
import {Cart, OrderProduct} from '../shopping/order';
import {PricingType} from '../products/product';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CheckoutDialogComponent} from './checkout-dialog.component';
import {SubscriptionDialogComponent} from './subscription-dialog.component';

@Component({
  selector: 'vni-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  @Input() retail = false;
  @Output() close = new EventEmitter();

  currentUser: User;
  currentUserSubscription: Subscription;

  cart: Cart;
  cartSubscription: Subscription;

  countryCodeSubscription: Subscription;
  pricingType: PricingType;

  constructor(
    public url: UrlService,
    private auth: AuthService,
    public orderService: OrderService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.currentUserSubscription = this.auth.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.pricingType = this.retail ? PricingType.RETAIL : PricingType.WHOLESALE;
      if (this.currentUser && this.currentUser.isDealer) {
        this.pricingType = PricingType.WHOLESALE;
      }

      this.orderService.refreshCart(this.pricingType);
    });

    this.cartSubscription = this.orderService.cart.subscribe(cart => this.cart = cart);
    this.countryCodeSubscription = this.auth.countryCode.subscribe(countryCode => this.orderService.refreshCart(this.pricingType));
  }

  ngOnDestroy() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }

    if (this.countryCodeSubscription) {
      this.countryCodeSubscription.unsubscribe();
    }
  }

  logout() {
    this.close.emit();
    setTimeout(() => this.auth.logout(), 500);
  }

  updateCart() {
    this.orderService.setCart(Object.assign({}, this.cart));
  }

  removeItem(productId: number) {
    this.orderService.removeFromCart(productId);
  }

  checkout() {
    const dialogRef = this.dialog.open(CheckoutDialogComponent, {panelClass: 'checkout-container', disableClose: true});
    dialogRef.componentInstance.retail = this.retail;
  }

  subscribe() {
    const dialogRef = this.dialog.open(SubscriptionDialogComponent, {panelClass: 'checkout-container', disableClose: true});
    dialogRef.componentInstance.retail = this.retail;
  }

  get subtotal() {
    return this.orderService.subtotal(this.cart.items);
  }

  get subscribableSubtotal() {
    return this.orderService.subtotal(this.cart.items, true);
  }

  get canSubscribe() {
    return this.cart.items.some(item => item.product.isSubscribable);
  }

  get cv() {
    return this.orderService.cv(this.cart.items);
  }

  get pv() {
    return this.orderService.pv(this.cart.items);
  }

  get itemCount() {
    return this.orderService.itemCount;
  }

  findBundle(): OrderProduct {
    return this.cart ? this.cart.items.find(item => item.product.isBundle) : null;
  }

  get errors() {
    const bundle = this.findBundle();
    return bundle ? bundle.errors : null;
  }
}
