import * as moment from 'moment';
import {Tag} from '../tags/tag';

export class Article {
  id: number;
  date: any;

  title: string;
  subtitle: string;
  caption: string;
  content: string;

  imgFileName: string;

  isEnabled: boolean;
  isArchived: boolean;

  tags: Tag[];
  isFeatured: boolean;

  static deserialize(article: Article) {
    article.date = moment(article.date).toDate();
  }
}

export interface Quote {
  imgSrc: string;
  content: string;
  name: string;
}
