import {Component, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AccountType} from '@vni/common';

@Component({
  templateUrl: './terms-and-conditions-dialog.component.html'
})
export class TermsAndConditionsDialogComponent {
  @Input() accountType: AccountType;

  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionsDialogComponent>) {
  }
}
